import React from 'react';

import './questionnaire.scss';
import THInput from '../../../shared/components/input/input';
import THRadio from '../../../shared/components/radio-button/radio-button';
import THSelect from '../../../shared/components/select/select';
import Request from '../../../request';
import Autocomplete from '../../../shared/components/autocomplete/autocomplete';
import THButton from '../../../shared/components/button/button';
import moment from 'moment';
import _ from 'lodash';
import ProgressBar from '../../../shared/components/utility/progress-bar/progress-bar';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { withUtilities } from '../../../shared/context/util';
import THSimpleDatePicker from '../../../shared/components/simple-date-picker/simple-datepicker';

const headline = ['', 'PERSONAL INFORMATION', 'PROFESSIONAL INFORMATION', 'COMPANY INFORMATION'];
const subheadline = ['', 'Please make sure your contact details are complete and accurate.', '', ''];

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      candidateObject: '',
      personalInfoObject: '',
      professionalInfoObject: '',
      selectedCountry: '',
      employmentStatus: '',
      companyInfoObject: '',
      candidateEmail: '',
      errors: {},
      loading: false,
    };
  }

  async componentDidMount() {
    let lastEntryJSON = Cookies.get('targetHype-questionnaire');
    if (lastEntryJSON) {
      let questionnaireObject = JSON.parse(lastEntryJSON);
      this.setState({
        candidateObject: questionnaireObject.candidateObject ? questionnaireObject.candidateObject : '',
        personalInfoObject: questionnaireObject.personalInfoObject ? questionnaireObject.personalInfoObject : '',
        professionalInfoObject: questionnaireObject.professionalInfoObject
          ? questionnaireObject.professionalInfoObject
          : '',
        companyInfoObject: questionnaireObject.companyInfoObject ? questionnaireObject.companyInfoObject : '',
        candidateEmail: questionnaireObject.candidateEmail ? questionnaireObject.candidateEmail : '',
      });
    }
  }

  updateCookie(objectName, object) {
    let lastEntryJSON = Cookies.get('targetHype-questionnaire');
    if (lastEntryJSON) {
      let questionnaireObject = JSON.parse(lastEntryJSON);
      questionnaireObject[objectName] = object;
      Cookies.set('targetHype-questionnaire', questionnaireObject);
    }
  }

  getFunctions = async () => {
    return await Request.get({ url: Request.requestMapping.util.functions });
  };

  getIndustries = async () => {
    return await Request.get({ url: Request.requestMapping.util.industries });
  };

  getCompanies = async namePart => {
    return await Request.get({ url: Request.requestMapping.util.companies, params: { name: namePart } });
  };

  getCountries = async () => {
    return await Request.get({ url: Request.requestMapping.util.countries });
  };

  getStates = async () => {
    return await Request.get({ url: Request.requestMapping.util.states });
  };

  sendActivationEmail = async () => {
    let intialPayload = {
      username: this.state.candidateEmail,
    };

    let { data, error } = await Request.post({
      url: Request.requestMapping.activationEmail,
      data: intialPayload,
    });
  };

  accountCreation = async event => {
    event.preventDefault();
    if (this.state.step !== 0) {
      return;
    }

    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    console.log('account');
    this.emailRef.setError('', true);
    this.registerAcceptance.setError('', true);
    this.passwordRef.setError('', true);
    this.repeatpassRef.setError('', true);

    let accepted = this.registerAcceptance.getData();

    let scrollIntoView = false;
    let password = '' + event.target.password.value;

    if (!accepted) {
      this.registerAcceptance.setError('Please accept the registration to continue forward.', scrollIntoView);
      scrollIntoView = true;
    }
    let inputEmail = event.target.email.value.toLowerCase();
    if (!inputEmail) {
      this.emailRef.setError('Please enter your primary email address.', scrollIntoView);
      scrollIntoView = true;
    }

    if (!emailRegex.test(inputEmail)) {
      this.emailRef.setError('Please provide a valid email address.', scrollIntoView);
      scrollIntoView = true;
    }

    if (!/[0-9]/.test(password)) {
      this.passwordRef.setError('Password must contain at least one number.', scrollIntoView);
      scrollIntoView = true;
    }

    if (!/[A-Z]/.test(password)) {
      this.passwordRef.setError('Password must contain at least one capital letter.', scrollIntoView);
      scrollIntoView = true;
    }

    if (!/[!-\/:-@[-`{-~]/.test(password)) {
      this.passwordRef.setError('Password must contain at least one special character.', scrollIntoView);
      scrollIntoView = true;
    }

    if (password.length < 8) {
      this.passwordRef.setError('Password must be at least 8 characters.', scrollIntoView);
      scrollIntoView = true;
    }

    let repeat_password = event.target.repeat_password.value;
    if (password !== repeat_password) {
      this.repeatpassRef.setError("Passwords don't match.", scrollIntoView);
      scrollIntoView = true;
    }

    if (scrollIntoView) {
      return;
    }

    let intialPayload = {
      password: password,
      username: inputEmail,
    };

    if (this.state.candidateEmail !== '' && this.state.candidateEmail === inputEmail) {
      this.setState({ step: this.state.step + 1 });
      return;
    }

    if (this.state.candidateEmail !== '' && this.state.candidateEmail !== inputEmail && this.state.candidateObject) {
      intialPayload.id = this.state.candidateObject.id;

      this.setState({ loading: true }, async () => {
        let { data, error } = await Request.patch({
          url: Request.requestMapping.register,
          data: intialPayload,
        });

        if (data) {
          this.updateCookie('candidateObject', data);
          this.updateCookie('candidateEmail', inputEmail);
          this.setState({
            loading: false,
            candidateObject: data,
            candidateEmail: inputEmail,
            step: this.state.step + 1,
          });
          return;
        } else if (error) {
          console.log(error);
        }
        throw error;
      });
    } else {
      this.setState({ loading: true }, async () => {
        this.setState({ candidateEmail: inputEmail });
        let { data, error } = await Request.post({
          url: Request.requestMapping.register,
          data: intialPayload,
        });

        if (data) {
          this.updateCookie('candidateObject', data);
          this.updateCookie('candidateEmail', inputEmail);
          this.setState({ loading: false, candidateObject: data, step: this.state.step + 1 });
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return;
        } else if (error) {
          this.setState({ loading: false });
          if (error.message === 'User Already Exists') {
            this.emailRef.setError(
              <React.Fragment>
                You already have a profile with Target Hype. Please proceed to login
                <Link to="/login" className="register-button">
                  <b> here</b>
                </Link>
              </React.Fragment>
            );
            return;
          }
        }
        this.setState({ loading: false });
        throw error;
      });
    }
  };

  personalInfo = async event => {
    event.preventDefault();
    if (this.state.step !== 1) {
      return;
    }
    let errors = false;
    let personalInfoPayload = {
      candidateInfo: this.state.personalInfoObject ? this.state.personalInfoObject : {},
      candidateContact: {},
    };

    this.gender.setError('', true);
    this.education.setError('', true);
    this.employment.setError('', true);
    this.country_code.setError('', true);

    this.first_name.setError('', true);
    this.last_name.setError('', true);
    this.dob.setError('', true);

    if (this.state_input) {
      this.state_input.setError('', true);
    }
    if (this.stateRef) {
      this.stateRef.setError('', true);
    }
    this.zip.setError('', true);
    this.phone.setError('', true);
    this.mobile.setError('', true);
    this.city.setError('', true);
    let scrollIntoView = false;

    if (event.target.first_name.validity.valid) {
      personalInfoPayload.candidateInfo.firstName = event.target.first_name.value;
    } else {
      errors = true;
      this.first_name.setError('Please enter your first name.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    personalInfoPayload.candidateInfo.middleName = event.target.middle_name.value;

    if (event.target.last_name.validity.valid) {
      personalInfoPayload.candidateInfo.lastName = event.target.last_name.value;
    } else {
      errors = true;
      this.last_name.setError('Please enter your last name.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let countryCode = this.country_code.getData();

    if (countryCode) {
      personalInfoPayload.candidateContact.country_code = parseInt(countryCode.id);
    } else {
      errors = true;
      this.country_code.setError('Please choose your country of residence.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (
      countryCode &&
      countryCode.name
        .trim()
        .toLowerCase()
        .includes('united states')
    ) {
      let selectedState = this.stateRef.getData();
      if (selectedState) {
        personalInfoPayload.candidateContact.state = selectedState.abbreviation;
      } else {
        errors = true;
        this.stateRef.setError('Please enter your state of residence.', scrollIntoView);
        if (!scrollIntoView) {
          scrollIntoView = !scrollIntoView;
        }
      }
    } else {
      if (event.target.state.validity.valid) {
        personalInfoPayload.candidateContact.state = event.target.state.value;
      } else {
        errors = true;
        this.state_input.setError(
          'Please enter your state / province of residence. If not applicable, please write in NA.',
          scrollIntoView
        );
        if (!scrollIntoView) {
          scrollIntoView = !scrollIntoView;
        }
      }
    }

    if (event.target.city.validity.valid) {
      personalInfoPayload.candidateContact.city = event.target.city.value;
    } else {
      errors = true;
      this.city.setError('Please enter your city / town of residence.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (event.target.zip.validity.valid) {
      personalInfoPayload.candidateContact.zip = event.target.zip.value;
    } else {
      errors = true;
      this.zip.setError('Please enter your zip / postal code.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (event.target.phone.validity.valid) {
      personalInfoPayload.candidateContact.phone = event.target.phone.value;
    } else {
      errors = true;
      this.phone.setError('Please enter your phone number.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    if (event.target.mobile.validity.valid) {
      personalInfoPayload.candidateContact.mobile = event.target.mobile.value;
    } else {
      errors = true;
      this.mobile.setError('Please enter your mobile phone number.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let dateOfBirth = this.dob.getData();
    if (dateOfBirth) {
      personalInfoPayload.candidateInfo.dob = moment(dateOfBirth).toDate();
    }

    // if (event.target.dob.validity.valid) {
    //   if (moment(event.target.dob.value).year() > moment().year() || +moment(event.target.dob.value).year() < 1910) {
    //     errors = true;
    //     this.dob.setError('Please enter a valid date of birth.', scrollIntoView);
    //     if (!scrollIntoView) {
    //       scrollIntoView = !scrollIntoView;
    //     }
    //   } else {
    //     personalInfoPayload.candidateInfo.dob = moment(event.target.dob.value).toDate();
    //   }
    // } else {
    //   errors = true;
    //   this.dob.setError('Please enter a valid date of birth.', scrollIntoView);
    //   if (!scrollIntoView) {
    //     scrollIntoView = !scrollIntoView;
    //   }
    // }

    let genderData = this.gender.getData();

    if (genderData !== null && genderData !== undefined && genderData !== '') {
      personalInfoPayload.candidateInfo.gender = genderData;
    } else {
      errors = true;
      this.gender.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let educationData = this.education.getData();
    if (educationData !== null && educationData !== undefined && educationData !== '') {
      personalInfoPayload.candidateInfo.education = educationData;
    } else {
      errors = true;
      this.education.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    //Candidate Personal Info
    let empStatus = this.employment.getData();
    if (empStatus) {
      this.setState({ employmentStatus: empStatus });
    } else {
      this.employment.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
      errors = true;
    }

    personalInfoPayload.candidateContact.email = this.state.candidateEmail;

    personalInfoPayload.candidateInfo.candidate = this.state.candidateObject;
    personalInfoPayload.candidateContact.candidate = this.state.candidateObject;

    if (errors) {
      return;
    }

    if (this.state.personalInfoObject) {
      this.setState({ loading: true }, async () => {
        let { data: patchData, error: patchError } = await Request.patch({
          url: Request.requestMapping.personalInfo,
          data: personalInfoPayload,
        });

        if (patchData) {
          this.setState({ step: this.state.step + 1, personalInfoObject: personalInfoPayload, loading: false });
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return;
        } else if (patchError) {
          this.setState({ loading: false });
          console.log(patchError);
          throw patchError;
        }
        throw patchError;
      });
    } else {
      this.setState({ loading: true }, async () => {
        let { data, error } = await Request.post({
          url: Request.requestMapping.personalInfo,
          data: personalInfoPayload,
        });

        if (data) {
          this.updateCookie('personalInfoObject', personalInfoPayload);

          let emptyEmpStatus = {
            candidate: this.state.candidateObject,
            employmentStatus: this.state.employmentStatus,
            seniority: 0,
            function: 0,
            jobTitle: '',
            loading: false,
            employmentStartDate: null,
          };

          if (this.state.employmentStatus >= 5) {
            let { empData, empError } = await Request.post({
              url: Request.requestMapping.professionalInfo,
              data: emptyEmpStatus,
            });
          }

          this.setState({
            step: this.state.employmentStatus >= 5 ? this.state.step + 3 : this.state.step + 1,
            personalInfoObject: personalInfoPayload,
            loading: false,
          });
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return;
        } else if (error) {
          this.setState({ loading: false });
          console.log(error);
          throw error;
        }
        throw error;
      });
    }
  };

  employmentInfo = async event => {
    event.preventDefault();
    let scrollIntoView = false;
    if (this.state.step !== 2) {
      return;
    }
    let employmentInfo = {};
    this.seniority.setError('', true);
    this.proffession.setError('', true);
    this.job_title.setError('', true);

    employmentInfo.candidate = this.state.candidateObject;

    employmentInfo.employmentStatus = this.state.employmentStatus;

    let senior = this.seniority.getData();

    if (senior) {
      employmentInfo.seniority = senior;
    } else {
      this.seniority.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let functionData = this.proffession.getData();
    if (functionData) {
      employmentInfo.function = functionData.id;
    } else {
      this.proffession.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    if (event.target.job_title.value) {
      employmentInfo.jobTitle = event.target.job_title.value;
    } else {
      this.job_title.setError('Please enter your current job title.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    // if (
    //   event.target.start_date.value &&
    //   !(
    //     moment(event.target.start_date.value).year() > moment().year() ||
    //     moment(event.target.start_date.value).year() < 1910
    //   )
    // ) {
    //   employmentInfo.employmentStartDate = moment(event.target.start_date.value).toDate();
    // } else {
    //   this.start_date.setError('Please enter a valid start date.', scrollIntoView);
    //   if (!scrollIntoView) {
    //     scrollIntoView = !scrollIntoView;
    //   }
    // }

    let empStartDate = this.start_date.getData();

    employmentInfo.employmentStartDate = moment(empStartDate).toDate();

    if (scrollIntoView) {
      return;
    }

    if (this.state.professionalInfoObject) {
      this.setState({ loading: true }, async () => {
        let { data, error } = await Request.patch({
          url: Request.requestMapping.professionalInfo,
          data: employmentInfo,
        });

        if (data) {
          this.setState({ loading: false, step: this.state.step + 1, professionalInfoObject: employmentInfo });
          console.log(data);
          return;
        } else if (error) {
          console.log(error);
          throw error;
        }
        throw error;
      });
    } else {
      this.setState({ loading: true }, async () => {
        let { data, error } = await Request.post({
          url: Request.requestMapping.professionalInfo,
          data: employmentInfo,
        });

        if (data) {
          this.updateCookie('professionalInfoObject', employmentInfo);
          this.setState({
            step: this.state.step + 1,
            professionalInfoObject: employmentInfo,
            loading: false,
          });
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return;
        } else if (error) {
          console.log(error);
          throw error;
        }
        throw error;
      });
    }
  };

  companyInfo = async event => {
    event.preventDefault();
    if (this.state.step !== 3) {
      return;
    }
    this.company_revenue.setError('');
    this.employee_range.setError('');
    this.company_industry.setError(``);
    this.companyName.setError('');
    let companyInfo = {};

    let scrollIntoView = false;

    let company = this.companyName.getData();
    companyInfo.candidate = this.state.candidateObject;

    if (company) {
      companyInfo.company = _.isObject(company) ? company : { id: null, name: company };
    } else {
      this.companyName.setError('Please enter your current company name.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let industry = this.company_industry.getData();
    if (industry) {
      companyInfo.industry = industry;
    } else {
      this.company_industry.setError(`Please select one option.`, scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let empRange = this.employee_range.getData();
    if (empRange) {
      companyInfo.employeeRangeLower = empRange[0];
      companyInfo.employeeRangeUpper = empRange[1];
    } else {
      this.employee_range.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let companyRevenue = this.company_revenue.getData();
    if (companyRevenue) {
      companyInfo.companyRevenueLower = companyRevenue[0];
      companyInfo.companyRevenueUpper = companyRevenue[1];
    } else {
      this.company_revenue.setError('Please select a company revenue range', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (scrollIntoView) {
      return;
    }

    if (this.state.companyInfoObject) {
      this.setState({ loading: true }, async () => {
        let { data, error } = await Request.patch({
          url: Request.requestMapping.companyInfo,
          data: companyInfo,
        });

        if (data) {
          this.updateCookie('companyInfoObject', companyInfo);
          this.setState({loading:false, step: this.state.step + 1, companyInfoObject: companyInfo });
          console.log(data);
          return;
        } else if (error) {
          console.log(error);
          throw error;
        }
        throw error;
      });
    } else {
      this.setState({}, async () => {
        let { data, error } = await Request.post({
          url: Request.requestMapping.companyInfo,
          data: companyInfo,
        });

        if (data) {
          this.updateCookie('companyInfoObject', companyInfo);
          this.setState({ step: this.state.step + 1, companyInfoObject: companyInfo, loading:false});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return;
        } else if (error) {
          console.log(error);
          throw error;
        }
        throw error;
      });
    }
  };

  checkForChanges = (objectToCheck, objectToCheckAgainst) => {
    let changesMade = false;
    if (objectToCheck) {
      let keyz = Object.keys(objectToCheck);
      keyz.forEach(key => {
        if (objectToCheck[key] !== objectToCheckAgainst[key]) {
          changesMade = true;
        }
      });
    }
    return changesMade;
  };

  render() {
    return (
      <div className="questionnaire-container">
        <div className="hero-image">
          <div className="hero-elements">
            <h1 className="hero-headline">REGISTRATION</h1>
          </div>
        </div>
        <div className="main-content">
          <div className="progress-bar-questionnaire-container">
            <ProgressBar percent={(this.state.step + 1) * 20 + '%'} />
          </div>
          {headline[this.state.step] && (
            <h1 style={{ fontSize: '40px', fontWeight: '200', textAlign: 'center' }}>{headline[this.state.step]}</h1>
          )}
          {subheadline[this.state.step] && (
            <p style={{ margin: '0 auto 0 auto', marginBottom: '50px' }}>{subheadline[this.state.step]}</p>
          )}
          <form
            // required
            id="account-create"
            noValidate
            onSubmit={this.accountCreation}
            className={this.state.step === 0 ? 'content-column' : 'content-column-hidden'}
          >
            <p className="ad-hoc-label">Target Hype is GDPR Compliant.</p>

            <p className="ad-hoc-label">
              GDPR is the new European legislation meant to replace the{' '}
              <a
                href="https://en.wikipedia.org/wiki/Data_Protection_Directive"
                rel="noopener noreferrer"
                target="_blank"
              >
                1995 Data Protection Directive
              </a>
              . This regulation, which{' '}
              <a
                href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32016R0679"
                rel="noopener noreferrer"
                target="_blank"
              >
                can be read in full on the CNIL website
              </a>
              , is the new European law governing the use and handling of individuals’ personal data.
            </p>

            <p className="ad-hoc-label">
              In line with GDPR requirements you can contact us at any time to edit or rectify your information, erase
              all your data, export your data for you, unsubscribe from any specific uses we make of your information
              (newsletter, emails, etc.), access the information we have about you and modify it.
            </p>

            <p className="ad-hoc-label">
              By accepting to register you are allowing for collection and processing of the information you provide
              during registration.
            </p>

            <p className="ad-hoc-label">
              Please note: this page explains the impact of GDPR on the use of Target Hype services only. It should not
              be considered as legal advice.
            </p>
            <THRadio
              ref={ref => (this.registerAcceptance = ref)}
              label="Are you willing to register with Target Hype?"
              buttons={[
                {
                  value: true,
                  label: 'Yes, I am willing to register',
                },
                {
                  value: false,
                  label: 'No, I am not willing to register',
                },
              ]}
            />
            <div className="divider"> </div>
            <THInput
              required
              lowercase
              ref={ref => (this.emailRef = ref)}
              type="email"
              name={'email'}
              label="Email Address"
              defaultValue=""
            />
            <THInput
              ref={ref => (this.passwordRef = ref)}
              required
              name={'password'}
              label="Password"
              type="password"
              defaultValue=""
              note={() => (
                <React.Fragment>
                  <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Password must be at least 8 characters.
                  </p>
                  <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Use at least one number, one special character, and one upppercase letter.
                  </p>
                </React.Fragment>
              )}
            />
            <THInput
              ref={ref => (this.repeatpassRef = ref)}
              required
              name={'repeat_password'}
              label="Confirm Password"
              type="password"
              defaultValue=""
            />

            <div className="divider"> </div>
            <div className="th-form-actions">
              <THButton disabled={this.state.loading} type="submit" text="NEXT" />
            </div>

            {/* <button type="submit">Tester</button> */}
          </form>

          {/* PERSONAL Information */}

          <form
            id="personal-info"
            onSubmit={this.personalInfo}
            noValidate
            className={this.state.step === 1 ? 'content-column' : 'content-column-hidden'}
          >
            <THInput
              required
              maxLength="50"
              ref={ref => (this.first_name = ref)}
              name={'first_name'}
              label="First name"
              defaultValue={this.state.personalInfoObject.firstName}
            />

            <THInput
              maxLength="50"
              name={'middle_name'}
              label="Middle name"
              defaultValue={this.state.personalInfoObject.middleName}
            />

            <THInput
              ref={ref => (this.last_name = ref)}
              required
              maxLength="50"
              name={'last_name'}
              label="Last name"
              defaultValue={this.state.personalInfoObject.lastName}
            />
            <THSelect
              required
              ref={ref => (this.country_code = ref)}
              name={'country_code'}
              label="Country"
              note={''}
              handleChange={value => this.setState({ selectedCountry: value ? value.name : '' })}
              dataSource={this.getCountries}
              displayName={'name'}
              defaultValue={this.state.personalInfoObject.country_code}
            />

            {this.state.selectedCountry &&
              this.state.selectedCountry
                .toLowerCase()
                .trim()
                .includes('united states') && (
                <THSelect
                  required
                  ref={ref => (this.stateRef = ref)}
                  name={'state'}
                  label="State"
                  note={''}
                  dataSource={this.getStates}
                  displayName={'abbreviation'}
                  defaultValue={this.state.personalInfoObject.state}
                />
              )}

            {(this.state.selectedCountry === '' ||
              (this.state.selectedCountry &&
                !this.state.selectedCountry
                  .toLowerCase()
                  .trim()
                  .includes('united states'))) && (
              <THInput
                ref={ref => (this.state_input = ref)}
                required
                maxLength="50"
                name={'state'}
                label="State/Province"
                defaultValue={this.state.personalInfoObject.state}
              />
            )}

            <THInput
              ref={ref => (this.city = ref)}
              required
              name={'city'}
              maxLength="50"
              label="City/Town"
              defaultValue={this.state.personalInfoObject.city}
            />

            <THInput
              ref={ref => (this.zip = ref)}
              required
              name={'zip'}
              maxLength="50"
              label="ZIP/Postal code"
              defaultValue={this.state.personalInfoObject.zip}
            />

            <THInput
              ref={ref => (this.phone = ref)}
              required
              name={'phone'}
              maxLength="50"
              type="tel"
              label="Phone number"
              defaultValue={this.state.personalInfoObject.phone}
            />
            <THInput
              ref={ref => (this.mobile = ref)}
              required
              name={'mobile'}
              maxLength="50"
              type="tel"
              label="Mobile phone number"
              defaultValue={this.state.personalInfoObject.mobile}
            />

            <div className="divider"> </div>

            <THSimpleDatePicker
              ref={ref => (this.dob = ref)}
              name={'dob'}
              label={() => (
                <span>
                  What is <b>your date of birth?</b>
                </span>
              )}
              defaultValue=""
              note={() => (
                <React.Fragment>
                  <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Please note that this information is to be used for classification purposes only.
                  </p>
                  {/* <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Please enter the date in the following format (MM/DD/YYYY).
                  </p> */}
                </React.Fragment>
              )}
            ></THSimpleDatePicker>

            {/* <THInput
              ref={ref => (this.dob = ref)}
              required
              name={'dob'}
              type="date"
              // pattern="[0-9]{4}[-/][0-9]{2}[-/][0-9]{2}"
              label={() => (
                <span>
                  What is <b>your date of birth?</b>
                </span>
              )}
              note={() => (
                <React.Fragment>
                  <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Please note that this information is to be used for classification purposes only.
                  </p>
                  <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Please enter the date in the following format (MM/DD/YYYY).
                  </p>
                </React.Fragment>
              )}
              defaultValue=""
            /> */}
            <THRadio
              required
              ref={ref => (this.gender = ref)}
              name={'gender'}
              label={() => (
                <span style={{ fontSize: '15px', color: '#313131', display: 'block', marginBottom: '1rem' }}>
                  * What is <b>your gender?</b>
                </span>
              )}
              note={() => (
                <React.Fragment>
                  <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                    Please note that this information is to be used for classification purposes only.
                  </p>
                </React.Fragment>
              )}
              buttons={this.props.getGenders}
            />

            <div className="divider"> </div>

            <THRadio
              required
              ref={ref => (this.education = ref)}
              name={'education'}
              label={() => (
                <span style={{ fontSize: '15px', color: '#313131', display: 'block', marginBottom: '1rem' }}>
                  *What is the <b>highest level of education</b> that you’ve <b>completed?</b>
                </span>
              )}
              buttons={this.props.getEducation}
            />

            <div className="divider"> </div>

            <THRadio
              required
              ref={ref => (this.employment = ref)}
              name={'employment'}
              label={
                <span>
                  * What is your <b>current employment status?</b>
                </span>
              }
              buttons={this.props.getEmployment}
            />
            <div className="divider"> </div>

            <div className="th-form-actions">
              <THButton disabled={this.state.loading} type="submit" text="NEXT" form="personal-info" />
              {/* <THButton onClick={() => this.setState({ step: this.state.step - 1 })} text="BACK" /> */}
            </div>
          </form>

          <form
            id="employment-info"
            onSubmit={this.employmentInfo}
            noValidate
            className={this.state.step === 2 ? 'content-column' : 'content-column-hidden'}
          >
            <div className="divider"> </div>

            <THRadio
              required
              ref={ref => (this.seniority = ref)}
              name={'seniority'}
              label={
                <span>
                  * Which one of these is the <b>best fit</b> to your <b>current seniority level?</b>
                </span>
              }
              buttons={this.props.getSeniority}
            />

            <div className="divider"> </div>

            <THSelect
              ref={ref => (this.proffession = ref)}
              name={'industry'}
              label={() => (
                <span>
                  * Which of the following is the <b>best fit</b> to your <b>department / functional area</b> at your
                  current company?
                </span>
              )}
              note={() => (
                <div>
                  <p className="select-note">
                    <b>NOTE: This question pertains to your profession, regardless of your company industry.</b>
                  </p>
                  <p className="select-note">
                    If your <b>company's primary industry is Information Technologies</b>, and{' '}
                    <b>you are an HR Partner</b> in the company, <b>choose Human Resources</b> as your primary
                    functional area.
                  </p>
                  <p className="select-note">
                    If you are a <b>software developer supporting e.g. Finance Department</b> within the company,{' '}
                    <b> choose Information Technology</b> as your primary function.
                  </p>
                </div>
              )}
              dataSource={this.getFunctions}
              displayName={'name'}
            />

            <div className="divider"> </div>

            <THInput
              required
              ref={ref => (this.job_title = ref)}
              name={'job_title'}
              maxLength="100"
              label={() => (
                <span>
                  What is your <b>exact current job title</b> (as it would be written on your business card)?
                </span>
              )}
              defaultValue=""
            />

            <div className="divider"> </div>

            <THSimpleDatePicker
              ref={ref => (this.start_date = ref)}
              name={'start_date'}
              label={() => (
                <span>
                  {' '}
                  What is the <b>start date</b> of your engagement in your <b>current role?</b>
                </span>
              )}
              // note={() => (
              //   <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
              //     Please enter the date in the following format (MM/DD/YYYY).
              //   </p>
              // )}
              defaultValue=""
            ></THSimpleDatePicker>

            {/* <THInput
              ref={ref => (this.start_date = ref)}
              required
              name={'start_date'}
              type="date"
              label={() => (
                <span>
                  {' '}
                  What is the <b>start date</b> of your engagement in your <b>current role?</b>
                </span>
              )}
              note={() => (
                <p style={{ color: '#313131', fontSize: '12px', fontWeight: '600' }}>
                  Please enter the date in the following format (MM/DD/YYYY).
                </p>
              )}
              defaultValue=""
            /> */}

            <div className="divider"> </div>

            <div className="th-form-actions">
              <THButton disabled={this.state.loading} type="submit" text="NEXT" form="employment-info" />
              {/* <THButton onClick={() => this.setState({ step: this.state.step - 1 })} text="BACK" /> */}
            </div>
          </form>

          <form
            id="company-info"
            onSubmit={this.companyInfo}
            noValidate
            className={this.state.step === 3 ? 'content-column' : 'content-column-hidden'}
          >
            <Autocomplete
              filterField={'name'}
              maxLength="100"
              displayField={'name'}
              placeholder={''}
              ref={ref => (this.companyName = ref)}
              label={() => (
                <p style={{ fontSize: '15px', color: '#313131' }}>
                  * What is your <b>current company name?</b>
                </p>
              )}
              note={() => (
                <React.Fragment>
                  <p style={{ color: 'color: #313131', fontSize: '12px' }}>
                    Please be sure to <b>match it</b> to the company on our <b>auto-suggest list.</b>
                  </p>
                  <p style={{ color: 'color: #313131', fontSize: '12px' }}>
                    If your company is <b>not on the list</b>, please enter the company name{' '}
                    <b> as it would be written on your business card.</b>
                  </p>
                </React.Fragment>
              )}
              dataSource={this.getCompanies}
            />

            <div className="divider"> </div>

            <THSelect
              ref={ref => (this.company_industry = ref)}
              name={'company_industry'}
              label={() => (
                <p style={{ fontSize: '15px', color: '#313131' }}>
                  * What is your current <b>company's primary industry?</b>
                </p>
              )}
              note={''}
              dataSource={this.props.getIndustries}
              displayName={'name'}
            />

            <div className="divider"> </div>

            <THRadio
              required
              ref={ref => (this.employee_range = ref)}
              name={'employee_range'}
              label={() => (
                <p style={{ fontSize: '15px', color: '#313131' }}>
                  * Approximately how many <b>full-time employees</b> does your company have at all sites and locations,{' '}
                  <b>worldwide?</b>
                </p>
              )}
              note={() => (
                <p style={{ color: 'color: #313131', fontSize: '12px' }}>
                  <b>If unsure, please provide your best estimate.</b>
                </p>
              )}
              buttons={this.props.getEmployees}
            />

            <div className="divider"> </div>

            <THRadio
              required
              ref={ref => (this.company_revenue = ref)}
              name={'company_revenue'}
              label={() => (
                <p style={{ fontSize: '15px', color: '#313131' }}>
                  *What was your company's <b>total revenue</b> in{' '}
                  {moment()
                    .subtract(1, 'year')
                    .format('YYYY')}{' '}
                  <b>in US$?</b>
                </p>
              )}
              note={() => (
                <p style={{ color: 'color: #313131', fontSize: '12px' }}>
                  <b>If unsure, please provide your best estimate.</b>
                </p>
              )}
              buttons={this.props.getRevenue}
            />
            <div className="divider"> </div>

            <div className="th-form-actions">
              <THButton disabled={this.state.loading} type="submit" text="SUBMIT" form="company-info" />
              {/* <THButton onClick={() => this.setState({ step: this.state.step - 1 })} text="BACK" /> */}
            </div>

            <div className="divider"> </div>
          </form>
          <div className={this.state.step === 4 ? '' : 'content-column-hidden'}>
            <h2 className="h2-center">ACTIVATE YOUR PROFILE</h2>
            <h3 className="h3-center">Thank you for completing your registration!</h3>

            <h3 className="h3-center">An email has been sent to you to activate your profile.</h3>

            <h3 className="h3-center">
              <b>Please click the link provided in the email for activation.</b>
            </h3>
            <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center', marginBottom: '25px' }}>
              <THButton onClick={this.sendActivationEmail} text="RESEND ACTIVATION EMAIL" />
            </div>
            {/* <h3 className="h3-center">
              <b> A $25* bonus amount will be added to your profile immediately upon email verification.</b>
            </h3>

            <h3 className="h3-center">
              The $25* bonus is paid out along with the participation incentive for your first research study
              participation. Each participation is subject to approval by our partners - market research firms
              conducting the study.
            </h3> */}

            <h3 className="h3-center">
              We look forward to our cooperation! If you have any questions, always feel free to email us at{' '}
              <a style={{ fontSize: '16px' }} href="mailto:recruiter@targethype.com">
                recruiter@targethype.com
              </a>
              .
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default withUtilities(Questionnaire);
