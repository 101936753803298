import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';
class B2BPage extends React.Component {
  render() {
    return (
      <div className="b2b-wrapper">
        <div className="b2b-container">
          <section>
            <div className="hero">
              <div className="hero-elements">
                <h1 className="hero-headline">
                  Your <b>studies and data</b> are only as good as the <b>people</b> who provide <b>insights.</b>
                </h1>
                <a href="mailto:recruiter@targethype.com" className="register-button">
                  GET IN TOUCH
                </a>
              </div>
            </div>
          </section>
        </div>
        <section>
          <p className="homepage-b2b">
            Target Hype custom recruits study participants who have the potential to contribute critical insights toward
            the learning objectives of your research studies.
          </p>
          <p className="homepage-subscript">
            With our global reach, Target Hype knows how to reach interested people, the relevant people, the right
            people for your research needs.
          </p>
        </section>

        <div className="divider"> </div>

        <div className="th-difference-container">
          <h1>THE TARGET HYPE DIFFERENCE</h1>
          <ul className="th-differences">
            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">1</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">It’s about partnership</span>
                  <span className="th-difference-sub">
                    Working with Target Hype means that you value our partnership approach to recruitment. Where other
                    recruiting firms go broad, we go deep, building enduring client relationships that strengthen over
                    time. Our collaborative mindset allows us to work effectively, even if you have multiple recruiters
                    on one study.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">2</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">It’s about transparency</span>
                  <span className="th-difference-sub">
                    Our word means something. Target Hype prides itself on providing realistic, conservative and
                    achievable feasibility assessments. We also provide early warning if an assignment veers off course,
                    with recommendations for corrective action.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">3</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">It’s about quality</span>
                  <span className="th-difference-sub">
                    It all starts with the screener. We work closely with you to collaboratively perfect screeners –
                    from minor tweaking to major surgery – to ensure that each screener is positioned to deliver the
                    best possible outcome.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">4</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">It’s about resourcefulness</span>
                  <span className="th-difference-sub">
                    Our priority is to ensure alignment between your screening criteria and potential study
                    participants. It’s not about blindly proposing superficial criteria matches, but about proposing a
                    suitable fit. At Target Hype we tap all available resources, both internally and externally, for
                    matching criteria from diverse industries and locations.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">5</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">It’s about mission-centric focus</span>
                  <span className="th-difference-sub">
                    We present suitable matches to you for review and approval. Given our single-minded focus on client
                    success, Target Hype clients only pay for successful participants, not for every potential
                    participant who passes the screener.
                  </span>
                </div>
              </div>
            </li>
          </ul>

          <p className="homepage-b2b">
            When you are a Target Hype client – a Target Hype partner – we are deeply invested in the success of your
            research. After all, your business success is what makes Target Hype succeed.
          </p>
        </div>
      </div>
    );
  }
}

export default B2BPage;
