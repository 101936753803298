import React, { Component } from 'react';
import THInput from '../../../../shared/components/input/input';
import THButton from '../../../../shared/components/button/button';
import Request from '../../../../request';
import { withRouter } from 'react-router';
import './activate-account.scss';

class ActivateAccount extends Component {
  async componentDidMount() {
    var jwtToken = this.props.match.params.jwt;
    let intialPayload = {
      jwt: jwtToken,
    };
    let { data, error } = await Request.post({
      url: Request.requestMapping.activateAccount,
      data: intialPayload,
    });
    if (data) {
      console.log(data);
    }
  }

  render() {
    return (
      <div className="b2b-wrapper activate-account-wrapper">
        <div className="th-difference-container">
          <h1>SUCCESS</h1>
          <div className="activate-account-paper">
            <p className="homepage-b2b" style={{ width: '100%' }}>
              Thank you for verifying your email address!
            </p>
            <div className="save-actionbar" style={{ display: 'flex', height: 'unset' }}>
              {/* <THButton type="submit" text="CLICK HERE TO LOGIN" style={{ margin: 'auto' }} /> */}
              <a href="/login" className="register-button">
                CLICK HERE TO LOGIN
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ActivateAccount);
