import React from 'react';

const icons = {
  linkedin: ({ width = 35, height = 35, className }) => (
    <svg
      className={className}
      x="0px"
      y="0px"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      enableBackground="new 0 0 35 35"
    >
      <g>
        <path
        className="st0"
          fill="#5AB2A5"
          d="M19.5,1.6C10.8,0.5,2.7,6.7,1.6,15.5s5.1,16.7,13.9,17.8s16.7-5.1,17.8-13.9C34.5,10.8,28.3,2.7,19.5,1.6z
             M12,26.9H7.8v-13H12V26.9z M9.9,12.1L9.9,12.1c-1.5,0-2.4-1-2.4-2.2c0-1.3,1-2.2,2.5-2.2s2.4,1,2.4,2.2S11.4,12.1,9.9,12.1z
             M27.6,26.9h-4.3v-7c0-1.7-0.6-2.9-2.2-2.9c-1.2,0-1.9,0.8-2.2,1.6c-0.1,0.3-0.2,0.6-0.2,1v7.2h-4.3c0,0,0.1-11.7,0-13h4.3v1.8
            c0.5-0.9,1.6-2.1,3.8-2.1c2.8,0,4.9,1.8,4.9,5.9L27.6,26.9L27.6,26.9z"
        />
        <circle className="st1" fill="none" stroke="#5AB2A5" strokeWidth="3" strokeMiterlimit="10" cx="17.5" cy="17.5" r="16" />
      </g>
    </svg>
  ),
  facebook: ({ width = 35, height = 35, className }) => (
    <svg
      className={className}
      x="0px"
      y="0px"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      enableBackground="new 0 0 35 35"
    >
      <g>
        <path
        className="st0"
          fill="#5AB2A5"
          d="M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z M22.4,10.9c-0.6,0-1.2,0-1.8,0
            c-0.8,0-1.3,0.4-1.4,1.1c0,0.6,0,1.2,0,1.8c0,0.2,0.1,0.2,0.3,0.2c0.9,0,1.9,0,2.8,0c0.3,0,0.4,0.1,0.3,0.3c-0.1,1-0.2,2-0.3,2.9
            c0,0.2-0.1,0.3-0.4,0.3c-0.7,0-1.5,0-2.2,0c-0.5,0-0.5-0.1-0.5,0.4c0,3.1,0,6.1,0,9.2c0,0.3-0.1,0.4-0.4,0.4c-1.3,0-2.5,0-3.8,0
            c-0.4,0-0.4-0.1-0.4-0.4c0-1.5,0-3.1,0-4.6c0-1.6,0-3.1,0-4.7c0-0.3-0.1-0.3-0.4-0.3c-0.5,0-1.1,0-1.6,0c-0.2,0-0.3,0-0.3-0.3
            c0-1,0-2,0-2.9c0-0.2,0.1-0.2,0.3-0.2c0.5,0,1.1,0,1.6,0c0.3,0,0.4-0.1,0.4-0.4c0-0.7,0-1.5,0-2.2c0-0.9,0.3-1.8,0.9-2.5
            c0.8-0.9,1.8-1.4,3.1-1.4c1.3-0.1,2.5,0,3.8,0c0.2,0,0.2,0.1,0.2,0.2c0,1,0,2,0,3C22.6,10.9,22.6,10.9,22.4,10.9z"
        />
      </g>
    </svg>
  ),

  skype: ({ width = 35, height = 35, className }) => (
    <svg
      className={className}
      x="0px"
      y="0px"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      enableBackground="new 0 0 35 35"
    >
      <g>
        <path
          fill="#5AB2A5"
          className="st0"
          d="M19.4,16.5L17,16c-0.9-0.2-1.9-0.5-1.9-1.3s0.7-1.5,2-1.5c2.6,0,2.5,1.9,3.8,1.9c0.7,0,1.3-0.4,1.3-1.1
		c0-1.7-2.6-2.9-4.9-2.9c-2.5,0-5,1-5,3.8c0,1.3,0.5,2.7,3,3.4l3.2,0.8c0.9,0.3,1.2,0.8,1.2,1.3c0,0.8-0.8,1.7-2.4,1.7
		c-2.9,0-2.5-2.4-4.1-2.4c-0.7,0-1.2,0.5-1.2,1.1c0,1.3,1.7,3.2,5.3,3.2c3.5,0,5.2-1.7,5.2-4C22.8,18.6,22,17,19.4,16.5z"
        />
        <path
          fill="#5AB2A5"
          className="st0"
          d="M17.5,1C8.4,1,1,8.4,1,17.5S8.4,34,17.5,34S34,26.6,34,17.5S26.6,1,17.5,1z M22.3,28.3c-1.1,0-2.1-0.3-3-0.8
		c-0.6,0.1-1.1,0.2-1.8,0.2c-5.7,0-10.2-4.5-10.2-10.2c0-0.6,0.1-1.2,0.2-1.8c-0.5-0.8-0.8-1.9-0.8-3c-0.1-3.4,2.6-6.1,5.9-6.1
		c1.1,0,2.2,0.3,3,0.8c0.6-0.1,1.1-0.2,1.8-0.2c5.7,0,10.2,4.5,10.2,10.2c0,0.6-0.1,1.2-0.2,1.8c0.5,0.8,0.8,1.9,0.8,3
		C28.3,25.6,25.6,28.3,22.3,28.3z"
        />
      </g>
      <circle className="st1" fill="none" stroke="#5AB2A5" strokeWidth="2" strokeMiterlimit="10" cx="17.5" cy="17.5" r="16.5" />
    </svg>
  ),
};

const Icon = ({ name, width, height, className }) => {
  if (!icons[name]) {
    throw new Error("Icon with provided name doesn't exist");
  }

  const IconComponent = icons[name];

  return <IconComponent width={width} className={className} height={height} />;
};

export default Icon;
