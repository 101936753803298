import React from 'react';
import _ from 'lodash';

import './radio-button.scss';

// buttonDef: {
//     label
//     value | defaultValue
//     disabled
//     visible
// }

export default class THRadio extends React.Component {
  static inputId = 0;
  myRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      actualValue: null,
      errorMessage: '',
      myOptions: [],
    };
  }

  componentDidMount() {
    this.getMyOptions();
  }

  getMyOptions = async () => {
    if (_.isFunction(this.props.buttons)) {
      let opts = await this.props.buttons();

      this.setState({ myOptions: opts.data });
    } else {
      this.setState({ myOptions: this.props.buttons });
    }
  };

  scrollIntoView = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  };

  getData() {
    return this.state.actualValue;
  }

  handleRadioChange(event, index) {
    if (this.props.handleChange) {
      this.props.handleChange(this.state.myOptions[index].value);
    }
    console.log('new radio button value', this.state.myOptions[index].value);
    this.setState({ selected: index, actualValue: this.state.myOptions[index].value });
  }

  setError = (errorMessage,dontScrollIntoView) => {
    this.setState({ errorMessage: errorMessage });
    if(!dontScrollIntoView){
      this.scrollIntoView();
    }
  };

  renderRadio(item, index) {
    switch (true) {
      case item.value !== undefined:
        return (
          <div
            ref={this.myRef}
            key={'contradio-' + (this.props.name ? this.props.name : 'na-') + index}
            className="single-radio-container"
          >
            <label
              className={this.state.selected === index ? 'checked-label' : ''}
              key={'labelradio-' + (this.props.name ? this.props.name : 'na-') + index}
            >
              <input
                required={this.props.required}
                key={'radio-' + (this.props.name ? this.props.name : 'na-') + index}
                type="radio"
                onChange={event => this.handleRadioChange(event, index)}
                checked={this.state.selected === index}
                value={_.isFunction(item.value) ? item.value() : item.value}
                name={item.name}
              />
              <span>{item.label ? item.label : item.name}</span>
            </label>
          </div>
        );
      case item.defaultValue !== undefined:
        return (
          <div
            key={'contradio-' + (this.props.name ? this.props.name : 'na-') + index}
            className="single-radio-container"
          >
            <input
              required={this.props.required}
              type="radio"
              key={'labelradio-' + (this.props.name ? this.props.name : 'na-') + index}
              checked={this.state.selected === index}
              defaultValue={_.isFunction(item.defaultValue) ? item.defaultValue() : item.defaultValue}
              name={this.props.name}
            />
            <label key={'radio-' + (this.props.name ? this.props.name : 'na-') + index}>{item.label}</label>
          </div>
        );
      default:
        return (
          <div className="single-radio-container">
            <input
              required={this.props.required}
              key={'radio-' + (this.props.name ? this.props.name : 'na-') + index}
              type="radio"
              name={item.name}
            />
            <label>{item.label}</label>
          </div>
        );
    }
  }
  render() {
    return (
      <div className="radio-container">
        {_.isFunction(this.props.label) ? (
          this.props.label()
        ) : (
          <label className="label" htmlFor={this.props.name}>
            {this.props.label}
          </label>
        )}

        {this.props.note &&
          (_.isFunction(this.props.note) ? (
            this.props.note()
          ) : (
            <div>
              <p className="input-note" />
            </div>
          ))}
        <div className="buttons-container">
          {this.state.myOptions && this.state.myOptions.map((item, index) => this.renderRadio(item, index))}
        </div>
        {this.state.errorMessage && <span className="error">{this.state.errorMessage}</span>}
      </div>
    );
  }
}
