import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';
class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <div className="terms-and-conditions-wrapper">
        <div className="divider"> </div>
        <div className="th-difference-container">
          <h1>PRIVACY & COOKIE POLICY</h1>

          <ul className="th-differences">
            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Legal Framework</span>
                  <span className="th-difference-sub">
                    This data privacy policy is written to comply with the European Union General Data Protection
                    Regulation (GDPR). GDPR gives specific data protection rights to individuals with regard to how
                    their personal data is collected stored and used.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Who we are</span>
                  <span className="th-difference-sub">
                    Target Hype doo (Target Hype) is a market research recruitment company. We recruit participants for
                    B2B qualitative market research studies, in partnership with global market research companies.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    We are the sole controller of the personal information collected and processed by Target Hype. Your
                    personal information is provided to third parties with your express permission and solely for the
                    purposes of participation in market research studies. Third parties do not have our permission to
                    use your personal information for any other purpose.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    We are the sole controller of the personal information of our staff.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    If you have any questions about our privacy policy, please contact us:
                    <br />
                    by email: recruiter@targethype.com
                    <br />
                    by postal service: Target Hype doo, Attn. Data Protection, #3, Rankeova str. 11000 Belgrade,
                    Serbia
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">How we use personal information</span>
                  <span className="th-difference-sub">
                    For the purposes of this Data Privacy Policy “Personal Data” consists of any personal information
                    which would reveal the identity of the owner either directly or indirectly in a private capacity.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Target Hype only collects and processes personal information in the legitimate interest of
                    conducting business with you, or to fulfil other legal, regulatory and contractual obligations. Some
                    of the personal information we collect, and process may be confidential and include details on your
                    professional career.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Examples of the type and sources of personal information collected and processed by us are set out
                    in the table below:
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Type of personal information: Individual details Registrants: <br />
                    Email Address, Name, Address, Phone number, Gender, Date of birth, Qualifications, Employment
                    history Employees: Email Address, Name, Address, Phone number, Gender, Date of birth,
                    Qualifications, Employment history
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Type of personal information: Identification details <br />
                    Employees: Personal ID, Proof of qualifications (diploma), Tax agency required, employment related
                    documentation
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Type of personal information: <br />
                    Financial information Employees: Bank account number, Bank account details
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Type of personal information: Health data <br />
                    Employees: Medical records related to current employment with Target Hype
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    Type of personal information: Criminal records <br />
                    Employees: Criminal convictions, Pending criminal charges
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    We sometimes need to share the personal information we collect and process with other organizations.
                    Some of these companies are based outside of the European Union where different data privacy laws
                    apply. In these circumstances, we have contractual terms in place to make sure that your information
                    remains safe and secure.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">
                    We will not share your information with anyone else unless you agree to this, or we are required to
                    do this by our regulators (e.g. the Serbian Tax Agency) or other authorities.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span className="th-difference-sub">We do not use automated profiling.</span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Keeping your personal information</span>
                  <span className="th-difference-sub">
                    Our retention periods for personal information are based on business needs and legal requirements.{' '}
                    <br />
                    We retain personal information for no longer than is necessary for the processing purpose(s) for
                    which the information was collected, and any other permissible, related purpose. When personal
                    information is no longer needed, we either irreversibly anonymize the data (in which case we may
                    further retain and use the anonymized information) or securely destroy the data.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">How we store personal information</span>
                  <span className="th-difference-sub">
                    In order to protect collected personal information, Target hype implements suitable physical,
                    technical and organization protective measures. All employees and external associates sign
                    Non-Disclosure agreements, thereby binding them to safeguard all business information, including
                    registrant personal information.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    All the personal data collected is stored in a protected database. The database can be accessed only
                    by authorized personnel working on specific market research studies. We use data protection security
                    tools, encrypting sensitive data and protecting them from unauthorized access, changes, loss, theft
                    or any other form of violation and misuse. In case of unauthorized access, we enforce all available
                    measures to mitigate the consequences, while relevant institutions and all affected registrants are
                    notified without delay
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">
                    How you can access your information and correct anything which is wrong
                  </span>
                  <span className="th-difference-sub">
                    As our registrant you can access, view and edit your profile by logging in to your profile,
                    therefore you have full control of the data we store on you. We want to make sure that your personal
                    information is accurate and up to date and may occasionally prompt you to update your information.
                    <br />
                    You may request anonymization of your historical data with us. We will comply with your request
                    within a reasonable timeframe, upon determining your identity.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    We may make a reasonable charge for our services regarding your personal data, or refuse to comply,
                    if your request is clearly unjustified or excessive.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    As a registrant you have the following rights, that can be exercised upon determining your identity:
                    <br />
                    - the right to be informed on how your data is processed
                    <br />
                    - the right of access, unless your personal data has been permanently destroyed
                    <br />
                    - the right to data portability i.e. to request a copy of your data
                    <br />
                    - the right to rectification, erasure, restrict or discontinue processing
                    <br />
                    - the right to retract your acceptance to data processing. The retraction does not affect the
                    legality of data processing base on original acceptance prior to its retraction.
                    <br />- the right to object
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Cookie Policy</span>
                  <span className="th-difference-sub">
                    You can visit our website without revealing who you are or providing any personal information about
                    yourself. If you do submit any personal information, for example by requesting a password, by doing
                    so you give your consent for such data to be used by us to process your enquiry or request; to
                    comply with any relevant law, regulation, or court order; to help improve our website or the
                    products or services we offer; or to understand better our website users’ needs.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Data Protection</span>
                  <span className="th-difference-sub">
                    We automatically collect some information when you visit the web website because your IP address
                    needs to be recognized by the server. We may use this information to analyze how different parts of
                    our web website are used. We do not identify individual users in this type of research.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    You have the facility to submit personal data in the course of accessing our website only by
                    registering with Target Hype or sending us an email. We may use the information you submit in order
                    to process your enquiry or request; to comply with any relevant law, regulation, audit or court
                    order; to help improve our web website or the products or services we offer; or to understand better
                    our clients’ needs.
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    Upon receipt of your written request, we will disclose to you your personal data held by us, we
                    shall correct or delete inaccurate details, and notify any third-party recipients of the necessary
                    changes. We regularly delete data, which is no longer required. Website related requests can be made
                    by e-mail to recruiter@targethype.com.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Overview</span>
                  <span className="th-difference-sub">
                    A ‘cookie’ is a text-only string of information that a website transfers to the cookie file of the
                    browser on your computer’s hard disk so that the website can remember specific information about
                    your use of the website. This website uses Google Analytics. Google Analytics uses cookies to help
                    us see how many users come to our website and the pages that generate most interest. This
                    information is useful when we come to redesign the website to ensure the Target Hype website
                    continues to be an effective source of information. Google Analytics cookies do not enable any
                    website user to be individually identified and these cookies will only be used for website design
                    purposes.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    By using this website, you consent to the use of the Google Analytics cookies and IP address
                    collection in the manner and for the purposes set out above.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    We do <b>not</b> use any of the following cookies: Advertising cookies – These are set on the
                    website by ads such as banners <br />
                    Flash cookies – These are set by Flash objects to track behavior on site <br />
                    Marketing cookies – These are set by promotional activities such as email campaigns <br />
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Details about the cookies we use</span>
                  <span className="th-difference-sub">
                    A list of the cookies used on the Target Hype website and information about what they are used for
                    are contained in the text below. The Target Hype website is maintained using a Content Management
                    System (CMS).
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">CMS Cookies: Registered Users only</span>
                  <span className="th-difference-sub">
                    Cookie Name: MemberID <br />
                    Expiry: 1 day
                    <br />
                    Data Stored: JWT token. <br />
                    Purpose: This cookie is created at first login and is also created at every subsequent login. It
                    indicates that a user who is, or was, logged in is a registered user of the Target Hype website. As
                    the cookie has a 1-day duration, if more than 1 day has elapsed between logins, the cookie will
                    disappear and be recreated the next time the user logs in.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Changes to our privacy and cookie policy</span>
                  <span className="th-difference-sub">
                    We keep our privacy and cookie policy under regular review, and we will place any updates on this
                    web page.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">This privacy policy was last updated on November 1st 2019</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyPage;
