import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
class IndividualsPage extends React.Component {
  render() {
    return (
      <div className="individuals-wrapper">
        <div className="individuals-container">
          <section>
            <div className="hero">
              <div className="hero-elements">
                <h1 className="hero-headline">
                  We seek registrants who <b>share our values</b> –
                  transparency, reliability and efficiency.
                </h1>
                <a
                  href="https://mailchi.mp/targethype.com/register"
                  className="register-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  REGISTER WITH TARGET HYPE
                </a>
              </div>
            </div>
          </section>
        </div>
        <section>
          <p className="homepage-b2b">
            Are you able to articulate your views and opinions clearly and
            succinctly, without hesitation? Would you like to profit from
            honestly sharing your professional insights, while respecting your
            company’s proprietary information?
          </p>

          <p className="homepage-subscript">
            We recruit participants for B2B qualitative market research studies
            for trusted partners who are big brand name global market research
            companies.
          </p>

          <p className="homepage-subscript">
            Market research firms we partner with look to Target Hype
            registrants to gain deeper insight into business needs and
            decision-making processes, so they can help their own clients
            understand the market needs, innovate, grow, succeed.
          </p>
        </section>

        <div className="divider"> </div>

        <div className="th-difference-container">
          <h1>HOW IT WORKS</h1>
          <ul className="th-differences">
            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">1</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Registration</span>
                  <span className="th-difference-sub">
                    Complete the 1-minute registration form. All the questions
                    are asked with the{" "}
                    <b style={{ color: "#313131" }}>
                      aim to match your profile to a research study
                    </b>{" "}
                    that is likely to be a suitable fit.{" "}
                    <b style={{ color: "#313131" }}>
                      Please focus on your current primary expertise.
                    </b>{" "}
                    All research studies include additional pre-qualification
                    criteria.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">2</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Invitation</span>
                  <span className="th-difference-sub">
                    If your profile is a match to basic research study criteria,{" "}
                    <b style={{ color: "#313131" }}>
                      we will invite you by email
                    </b>{" "}
                    with your unique link to the online pre-qualification form.
                    We will share the study format, time commitment and cash
                    incentive amount you would be paid upon successful
                    participation, if selected.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">3</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Participation</span>
                  <span className="th-difference-sub">
                    Complete the online pre-qualification form using the unique
                    link we sent you.
                    <b style={{ color: "#313131" }}>
                      {" "}
                      Your answers, but not your private information, are sent
                      to our partner for review.
                    </b>{" "}
                    Please always ensure your responses are truthful and current
                    - that is always a prerequisite for successful
                    participation. Once we get approval from our partner, we
                    will contact you again by email and/or phone to schedule
                    your participation in our partner’s research study.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number">4</span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Payment</span>
                  <span className="th-difference-sub">
                    After successfully completing your participation in a study,
                    you will receive your cash incentive by email within 7 to 10
                    business days. Redeeming your participation incentive is
                    intuitive, and you will have several options to choose from,
                    based on your location, including PayPal or bank transfers.
                    Target Hype does not request or handle your payment
                    information. It is all handled by a 3<sup>rd</sup> party
                    provider.
                  </span>
                </div>
              </div>
            </li>
          </ul>

          <p className="homepage-b2b">
            <a
              href="https://mailchi.mp/targethype.com/register"
              className="individuals-register-button"
              target="_blank"
              rel="noreferrer"
            >
              REGISTER WITH TARGET HYPE
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default IndividualsPage;
