import React from "react";
import { NavLink } from "react-router-dom";
import thLogo from "../../../assets/thLogo.png";
import smallThLogo from "../../../assets/TargetHype-small-logo.svg";
import { TweenLite } from "gsap";
import { Power1 } from "gsap/all";
import "./header.scss";
import { withUserInfo } from "../../context/user";
import { CSSTransition } from "react-transition-group";
class Header extends React.Component {
  state = {
    scrolled: true,
    hamburgerOpen: false,
  };

  logout = () => {
    this.props.updateUserId(-1);
  };

  componentDidMount() {
    // window.addEventListener('scroll', this.layout);
    this.group = document.querySelector(".group");
    this.body = document.querySelector("body");
    this.nodes = document.querySelectorAll(".navbar-element");
    this.logo = document.querySelector(".header-logo-image");
    this.total = this.nodes.length;
    this.ease = Power1.easeInOut;
    this.boxes = [];

    for (let i = 0; i < this.total; i++) {
      let node = this.nodes[i];

      // Initialize transforms on node
      TweenLite.set(node, { x: 0 });

      this.boxes[i] = {
        transform: node._gsTransform,
        x: node.offsetLeft,
        y: node.offsetTop,
        node,
      };
    }
  }

  layout = () => {
    if (window.scrollY > 15) {
      // if (!lastScrolled) {
      this.group.classList.add("scrolled");
      // this.logo.classList.add('small--logo');
      // }
      this.setState({ scrolled: false });
    } else {
      // if (lastScrolled) {
      this.group.classList.remove("scrolled");
      // this.logo.classList.remove('small--logo');
      // }
      this.setState({ scrolled: true });
    }
    let ease = this.ease;
    // if (newScrolled !== lastScrolled) {
    for (var i = 0; i < this.total; i++) {
      var box = this.boxes[i];

      var lastX = box.x;
      var lastY = box.y;

      box.x = box.node.offsetLeft;
      box.y = box.node.offsetTop;

      // Continue if box hasn't moved
      if (lastX === box.x && lastY === box.y) continue;

      // Reversed delta values taking into account current transforms
      var x = box.transform.x + lastX - box.x;
      var y = box.transform.y + lastY - box.y;
      // Tween to 0 to remove the transforms
      TweenLite.fromTo(box.node, 0.6, { x, y }, { x: 0, y: 0, ease });
    }
    // }
  };

  toggleHamburger = () => {
    this.setState({ hamburgerOpen: !this.state.hamburgerOpen });
  };

  render() {
    const loggedIn = this.props.userID !== -1;

    return (
      <div className="header-wrapper">
        <div className="header-container-mobile">
          <img
            style={{
              height: "50px",
              alignItems: "center",
            }}
            draggable="false"
            src={smallThLogo}
            alt="logo"
          />

          <div
            className={
              this.state.hamburgerOpen ? "container change" : "container"
            }
            onClick={this.toggleHamburger}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>

        <div className={"header-container group"}>
          <div
            className="central-logo navbar-element"
            style={{
              margin: this.state.scrolled ? "auto" : "auto auto auto 0",
              height: this.state.scrolled ? "150px" : "100px",
            }}
          >
            <img
              className="header-logo-image"
              style={{ opacity: this.state.scrolled ? 1 : 0 }}
              draggable="false"
              src={thLogo}
              alt="logo"
            />

            <img
              className="header-logo-image"
              style={{
                opacity: this.state.scrolled ? 0 : 1,
                height: "50px",
                alignItems: "center",
                left: "50%",
                transform: "translate(-50%, 0)",
              }}
              draggable="false"
              src={smallThLogo}
              alt="logo"
            />
          </div>

          <div
            style={{
              margin: this.state.scrolled ? "auto" : "auto 0 auto auto",
            }}
            className="navbar navbar-element centered"
          >
            <ul>
              <li className="nav-item not-last">
                <NavLink exact to="/" activeClassName="selected">
                  HOME
                </NavLink>
                {/* <a>HOME</a> */}
              </li>
              <li className="nav-item not-last">
                <NavLink to="/businesses" activeClassName="selected">
                  BUSINESSES
                </NavLink>
                {/* <a>ABOUT</a> */}
              </li>
              <li className="nav-item not-last">
                <NavLink to="/individuals" activeClassName="selected">
                  INDIVIDUALS
                </NavLink>
                {/* <a>ABOUT</a> */}
              </li>
              <li className="nav-item">
                <NavLink to="/about" activeClassName="selected">
                  ABOUT
                </NavLink>
                {/* <a>ABOUT</a> */}
              </li>
              {/* <li className="nav-item">
                {!loggedIn && (
                  <NavLink to="/register" activeClassName="selected">
                    REGISTER
                  </NavLink>
                )}
                {loggedIn && (
                  <NavLink to="/profile" activeClassName="selected">
                    PROFILE
                  </NavLink>
                )}
              </li> */}
              {/* <li className="nav-item">
                {!loggedIn && (
                  <NavLink to="/login" activeClassName="selected">
                    LOGIN
                  </NavLink>
                )}
                {loggedIn && (
                  <NavLink
                    onClick={this.props.logout}
                    exact
                    to="/"
                    activeClassName="selected"
                  >
                    LOGOUT
                  </NavLink>
                )}
              </li> */}
            </ul>
          </div>
        </div>
        <React.Fragment>
          {this.state.hamburgerOpen && (
            <CSSTransition
              transitionName="hamburger"
              transitionAppear={true}
              transitionAppearTimeout={400}
              transitionLeaveTimeout={300}
              transitionEnterTimeout={300}
              transitionEnter={true}
              transitionLeave={true}
            >
              <div
                className="navbar navbar-element"
                style={{
                  zIndex: "30",
                  position: "fixed",
                  top: "55px",
                  width: "100%",
                  height: "100%",
                  background: "white",
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <li
                    style={{ paddingBottom: "20px" }}
                    onClick={() =>
                      this.setState({ hamburgerOpen: false }, () =>
                        window.scrollTo(0, 0)
                      )
                    }
                    className="nav-item"
                  >
                    <NavLink
                      style={{ fontSize: "18px" }}
                      exact
                      to="/"
                      activeClassName="selected"
                    >
                      HOME
                    </NavLink>
                    {/* <a>HOME</a> */}
                  </li>
                  <li
                    style={{ paddingBottom: "20px" }}
                    onClick={() =>
                      this.setState({ hamburgerOpen: false }, () =>
                        window.scrollTo(0, 0)
                      )
                    }
                    className="nav-item"
                  >
                    <NavLink
                      style={{ fontSize: "18px" }}
                      to="/businesses"
                      activeClassName="selected"
                    >
                      BUSINESSES
                    </NavLink>
                    {/* <a>ABOUT</a> */}
                  </li>
                  <li
                    style={{ paddingBottom: "20px" }}
                    onClick={() =>
                      this.setState({ hamburgerOpen: false }, () =>
                        window.scrollTo(0, 0)
                      )
                    }
                    className="nav-item"
                  >
                    <NavLink
                      style={{ fontSize: "18px" }}
                      to="/individuals"
                      activeClassName="selected"
                    >
                      INDIVIDUALS
                    </NavLink>
                    {/* <a>ABOUT</a> */}
                  </li>
                  <li
                    style={{ paddingBottom: "20px" }}
                    onClick={() =>
                      this.setState({ hamburgerOpen: false }, () =>
                        window.scrollTo(0, 0)
                      )
                    }
                    className="nav-item"
                  >
                    <NavLink
                      style={{ fontSize: "18px" }}
                      to="/about"
                      activeClassName="selected"
                    >
                      ABOUT
                    </NavLink>
                    {/* <a>ABOUT</a> */}
                  </li>
                  <li
                    style={{ paddingBottom: "20px" }}
                    onClick={() =>
                      this.setState({ hamburgerOpen: false }, () =>
                        window.scrollTo(0, 0)
                      )
                    }
                    className="nav-item"
                  >
                    {!loggedIn && (
                      <NavLink
                        style={{ fontSize: "18px" }}
                        to="/register"
                        activeClassName="selected"
                      >
                        REGISTER
                      </NavLink>
                    )}
                    {loggedIn && (
                      <NavLink
                        style={{ fontSize: "18px" }}
                        to="/profile"
                        activeClassName="selected"
                      >
                        PROFILE
                      </NavLink>
                    )}
                  </li>
                  <li
                    style={{ paddingBottom: "20px" }}
                    onClick={() =>
                      this.setState({ hamburgerOpen: false }, () =>
                        window.scrollTo(0, 0)
                      )
                    }
                    className="nav-item"
                  >
                    {!loggedIn && (
                      <NavLink
                        style={{ fontSize: "18px" }}
                        to="/login"
                        activeClassName="selected"
                      >
                        LOGIN
                      </NavLink>
                    )}
                    {loggedIn && (
                      <NavLink
                        style={{ fontSize: "20px" }}
                        onClick={this.props.logout}
                        exact
                        to="/"
                        activeClassName="selected"
                      >
                        LOGOUT
                      </NavLink>
                    )}
                  </li>
                </ul>
                <div
                  style={{
                    marginBottom: "10px",
                    paddingLeft: "16px",
                    fontSize: "10px",
                    fontWeight: "500",
                    height: "30px",
                    bottom: "16px",
                  }}
                  className="copyright-info"
                >
                  Copyright © 2019 Targethype. All rights reserved.
                </div>
              </div>
            </CSSTransition>
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default withUserInfo(Header);
