import React from 'react';
import underConstruction from '../assets/under-construction.png';
import logo from '../assets/target-hype-text.png';

import './under-construction.scss';
export default class UnderConstruction extends React.Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          {/* <h2>TargetHype is under construction.</h2> */}
          <div>
            <img draggable="false" src={logo} alt="Target Hype" className="logo-image" />
          </div>
          <div>
            <img draggable="false" src={underConstruction} alt="Under Construction" className="logo-images" />
          </div>
          <p className="under-construction">
            We’re aiming for quality over speed. Our website should be up and running in August/September 2019. Please
            do visit us then, and we invite you to share your thoughts, views, likes and dislikes with us at{' '}
            <a className="recruiter-link" href="mailto:recruiter@targethype.com">
              recruiter@targethype.com
            </a>{' '}
            We’ll be sure to read your feedback, and work on adjusting all in our power, taking it step by step.
          </p>
        </header>
      </div>
    );
  }
}
