import React from 'react';
import _ from 'lodash';
import './simple-datepicker.scss';
import moment from 'moment';
import THSelect from '../select/select';
import THInput from '../input/input';
export default class THSimpleDatePicker extends React.Component {
  static inputId = 0;
  myRef = React.createRef();
  inputRef = React.createRef();

  years = [];
  months = [
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' },
  ];

  constructor(props) {
    super(props);
    for (let i = moment().year(); i >= 1900; i--) {
      this.years.push({ value: i, name: i });
    }
    let startingDate = this.props.defaultValue ? moment(this.props.defaultValue).date() : '';
    this.state = {
      errorMessage: this.props.errorMessage ? this.props.errorMessage : '',
      showCapsPopup: false,
      dayOfBirth: startingDate,
      birthMonth: '',
      birthYear: '',
    };
  }

  getData() {
    
    let birthDate = this.birthDate.inputRef.current ? this.birthDate.inputRef.current.value : null;
    let birthMonth = this.birthMonth.getData() ? this.birthMonth.getData().value : null;
    let birthYear = this.birthYear.getData() ? this.birthYear.getData().value : null;
    if (!birthDate || !birthMonth || !birthYear) {
      return null;
    }
    
    let theDate = moment(`${birthYear}/${birthMonth}/${birthDate}T12:00:00`, 'YYYY/MM/DDThh:mm:ss');
    return theDate;
  }

  componentDidMount() {}

  componentWillUnmount() {}

  setError = (errorMessage, dontScrollIntoView) => {
    this.setState({ errorMessage: errorMessage });
    if (!dontScrollIntoView) {
      this.scrollIntoView();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.defaultValue !== this.props.defaultValue) {
      let startingDate = this.props.defaultValue ? moment(this.props.defaultValue).date() : '';
      this.setState({ dayOfBirth: startingDate });
    }
  }

  setInfo = infoMessage => {
    this.setState({ infoMessage: infoMessage, errorMessage: '' });
  };

  scrollIntoView = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  };

  renderPlaceholder() {
    return <div className="placeholder"></div>;
  }

  legalDate = dateVal => {
    /// Every year that is exactly divisible by four is a leap year, except for years that are exactly divisible by 100, but these centurial years are leap years if they are exactly divisible by 400. For example, the years 1700, 1800, and 1900 are not leap years, but the years 1600 and 2000 are
    let birthYear = this.birthYear.getData() ? this.birthYear.getData().value : null;
    let birthMonth = this.birthMonth.getData() ? this.birthMonth.getData().value : null;
    if (!birthYear) {
      return true;
    }

    if (dateVal < 29) {
      return true;
    }

    if (dateVal <= 29 && birthYear && (birthYear % 400 === 0 || (birthYear % 100 !== 0 && birthYear % 4 === 0))) {
      return true;
    }

    if ((birthMonth === 4 || birthMonth === 6 || birthMonth === 9 || birthMonth === 11) && dateVal < 31) {
      return true;
    }

    if (
      birthMonth === 1 ||
      birthMonth === 3 ||
      birthMonth === 5 ||
      birthMonth === 7 ||
      birthMonth === 8 ||
      birthMonth === 10 ||
      birthMonth === 12
    ) {
      return true;
    }

    return false;
  };

  handleDayChange = event => {
    if (
      event.target.value.length === 0 ||
      (event.target.value.length <= 2 &&
        Number.parseInt(event.target.value) < 32 &&
        /^[0-9]*$/.test(event.target.value) &&
        this.legalDate(event.target.value))
    ) {
      this.setState({ dayOfBirth: event.target.value });
    }
  };

  render() {
    let selectedMonth = this.props.defaultValue ? this.months[moment(this.props.defaultValue).month()].value : '';
    let selectedYear = this.props.defaultValue ? moment(this.props.defaultValue).year() : '';
    return (
      <div ref={this.myRef} className="input-container">
        <label htmlFor={this.props.name}>
          {_.isFunction(this.props.label) ? (
            <React.Fragment>
              {this.props.required ? (
                <React.Fragment>
                  <span>
                    <b> * </b>
                  </span>
                  {this.props.label()}
                </React.Fragment>
              ) : (
                <React.Fragment>{this.props.label()}</React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>{this.props.required ? '*' + this.props.label : this.props.label}</React.Fragment>
          )}
        </label>
        {this.props.note &&
          (_.isFunction(this.props.note) ? (
            this.props.note()
          ) : (
            <div>
              <p className="input-note" />
            </div>
          ))}

        {this.props.initialLoad !== undefined && this.props.initialLoad && (
          <React.Fragment>{this.renderPlaceholder()}</React.Fragment>
        )}

        {(this.props.initialLoad === undefined || this.props.initialLoad === false) && (
          <div className="datepicker-grid-holder">
            <THSelect
              disabled={this.props.disabled}
              ref={ref => (this.birthMonth = ref)}
              name={'month'}
              placeholderOption="Month"
              note={''}
              displayName={'name'}
              comparisonValue="value"
              dataSource={this.months}
              defaultValue={selectedMonth}
            />
            <THInput
              disabled={this.props.disabled}
              ref={ref => (this.birthDate = ref)}
              onChange={this.handleDayChange}
              type="text"
              maxLength="2"
              placeholder="Day"
              value={this.state.dayOfBirth}
            />
            <THSelect
              disabled={this.props.disabled}
              ref={ref => (this.birthYear = ref)}
              name={'year'}
              placeholderOption="Year"
              note={''}
              displayName={'name'}
              comparisonValue="value"
              defaultValue={selectedYear}
              dataSource={this.years}
            />
          </div>
        )}
      </div>
    );
  }
}
