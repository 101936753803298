import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import moment from 'moment';
import aleks from '../../../assets/aleksandra_surlan.jpg';
import dim from '../../../assets/vladimir_obradovic.jpg';

class AboutPage extends React.Component {
  render() {
    return (
      <div className="about-wrapper">
        <div className="about-container">
          <section>
            <div className="hero">
              <div className="hero-elements">
                <h1 className="hero-headline">
                  <b>ABOUT US</b>
                </h1>
              </div>
            </div>
          </section>
          <section>
            <p className="homepage-b2b">
              We promise to treat you the way we like to be treated, with courtesy and respect for your time and
              commitment.
            </p>

            <div className="divider"> </div>

            <div className="about-us-grid">
              <div className="team-member-card">
                <div className="team-member-avatar">
                  <img src={aleks} alt={'Aleksandra Surlan'} className="team-member-avatar-image" />
                </div>
                <div className="team-member-bio">
                  <h1 className="team-member-name">Aleksandra Šurlan</h1>
                  <span className="team-member-function">CEO & Founder</span>
                  <p style={{ color: '#9b9a9a' }}>
                    Aleksandra founded Target Hype to provide global market research firms with high quality
                    participants who bring valuable insights for B2B market research studies. Aleksandra has deep
                    experience in market research recruitment and has worked closely with the world’s most respected
                    research firms, delivering on spec, high caliber study participants. Her goal in founding Target
                    Hype was to achieve a business based on mutual respect and all-around beneficial relationship – both
                    with study participants and research firms.
                  </p>
                </div>
              </div>

              <div className="team-member-card">
                <div className="team-member-avatar">
                  <img src={dim} alt={'Vladimir Obradovic'} className="team-member-avatar-image" />
                </div>
                <div className="team-member-bio">
                  <h1 className="team-member-name">Vladimir Obradović</h1>
                  <span className="team-member-function">VP, Digital Marketing</span>
                  <p style={{ color: '#9b9a9a' }}>
                    As an expert in the intersection of technology and marketing, Vladimir leads Target Hype’s digital
                    marketing efforts. His focus is on Target Hype’s digital footprint, expanding Target Hype’s
                    database, speaking to future registrants, and generating awareness of the firm’s recruitment
                    capabilities throughout the B2B market research vertical. Vladimir brings broad functional know how
                    to the team – from email marketing to social media campaign development to analytics – from diverse
                    B2B and B2C marketing and marketing operations experiences.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default AboutPage;
