import React, { Component } from 'react';
import THInput from '../../../../shared/components/input/input';
import THButton from '../../../../shared/components/button/button';
import Request from '../../../../request';
import { withRouter } from 'react-router';
import './reset-password.scss';
class ResetPassword extends Component {
  state = {
    passwordChanged: false,
  };
  submitPasswordChange = async event => {
    event.preventDefault();
    this.setState({ passwordChanged: false });
    var jwtToken = this.props.match.params.jwt;
    this.passwordRef.setError('');
    this.repeatPasswordRef.setError('');

    let pass = event.target.password.value;
    let repeatPass = event.target.repeat_password.value;

    if (pass.length < 8) {
      this.passwordRef.setError('Password must be at least 8 characters.');
      return;
    }
    if (!/[A-Z]/.test(pass)) {
      this.passwordRef.setError('Password must contain at least one capital letter.');
      return;
    }

    if (!/[0-9]/.test(pass)) {
      this.passwordRef.setError('Password must contain at least one number.');
      return;
    }

    if (!/[!-\/:-@[-`{-~]/.test(pass)) {
      this.passwordRef.setError('Password must contain at least one special character.');
      return;
    }

    if (pass !== repeatPass) {
      this.repeatPasswordRef.setError('Passwords do not match.');
      return;
    }

    let intialPayload = {
      newPassword: pass,
      jwt: jwtToken,
    };

    let { data, error } = await Request.patch({
      url: Request.requestMapping.changePassword,
      data: intialPayload,
    });
    if (data) {
      this.setState({ passwordChanged: true });
    }
  };

  render() {
    return (
      <div className="b2b-wrapper">
        <div className="th-difference-container">
          <h1>RESET PASSWORD</h1>
          <form onSubmit={this.submitPasswordChange} className="reset-password-paper">
            <div className="th-difference-point">
              <THInput
                lowercase
                ref={ref => (this.passwordRef = ref)}
                type="password"
                name={'password'}
                label={() => <span style={{ fontWeight: '600' }}>Password</span>}
                defaultValue=""
                note={() => (
                  <React.Fragment>
                    <p style={{ color: '#77787b', fontSize: '14px', fontWeight: '600' }}>
                      Password must be at least 8 characters.
                    </p>
                    <p style={{ color: '#77787b', fontSize: '14px', fontWeight: '600' }}>
                      Use at least one number, one special character,
                    </p>
                    <p style={{ color: '#77787b', fontSize: '14px', fontWeight: '600' }}>and one upppercase letter.</p>
                  </React.Fragment>
                )}
              />
            </div>

            <div className="th-difference-point">
              <THInput
                lowercase
                ref={ref => (this.repeatPasswordRef = ref)}
                type="password"
                name={'repeat_password'}
                label={() => <span style={{ fontWeight: '600' }}>Confirm new password</span>}
                defaultValue=""
              />
            </div>

            <div className="save-actionbar">
              <THButton type="submit" text="SAVE" />
            </div>
          </form>
          {this.state.passwordChanged && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: '24px',
              }}
            >
              <p style={{ margin: 'auto', color: '#5ab2a5', fontWeight: '600' }}>
                Your have successfully reset your password.
              </p>
              <p style={{ margin: 'auto', color: '#5ab2a5', fontWeight: '600' }}>
                {' '}
                Please proceed to{' '}
                <a href="/login" style={{ fontSize: '16px', cursor: 'pointer', color: '#b9d35e', fontWeight: '600' }}>
                  login
                </a>{' '}
                with the new password.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
