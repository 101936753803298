import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import Request from '../../../request';
import THButton from '../../../shared/components/button/button';
import THInput from '../../../shared/components/input/input';
import THSelect from '../../../shared/components/select/select';
import Autocomplete from '../../../shared/components/autocomplete/autocomplete';
import './styles.scss';

import { withUserInfo } from '../../../shared/context/user';
import { withUtilities } from '../../../shared/context/util';
import THSimpleDatePicker from '../../../shared/components/simple-date-picker/simple-datepicker';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      userID: '',
      userEmail: '',
      editLoginInfo: false,
      editPersonalInfo: false,
      editProfessionalInfo: false,
      editCompanyInfo: false,
      personalInfo: {},
      professionalInfo: {},
      companyInfo: {},
      contactInfo: {},
      candidate: {},
      saving: '',
    };
  }

  getFullProfile = async userID => {
    let { data, error } = await Request.get({ url: Request.requestMapping.candidate.full + '/' + userID });
    if (data) {
      let userData = await Request.get({
        url: `${Request.requestMapping.userInfo}/${userID}`,
      });
      if (userData && !userData.error) {
        this.setState({
          profileActivated: userData.data.active,
          userEmail:
            data.contactData[0] && data.contactData[0].email ? data.contactData[0].email : userData.data.username,
        });
      }

      this.setState({
        loading: false,
        candidate: data.candidate,
        personalInfo: data.personalInfo[0],
        professionalInfo: data.professionalInfo[0],
        companyInfo: data.companyData[0],
        contactInfo: data.contactData[0],

        selectedCountry:
          data.contactData && data.contactData[0] && data.contactData[0].country_code
            ? data.contactData[0].country_code
            : '',
      });
    }
  };

  getStates = async () => {
    return await Request.get({ url: Request.requestMapping.util.states });
  };

  getCountries = async () => {
    return await Request.get({ url: Request.requestMapping.util.countries });
  };

  componentDidMount() {
    this.getCountries();
    this.getUserObject();
    this.isActivated();
  }

  isActivated = async () => {
    let userID = await this.props.getUserID();
    let { data, error } = await Request.get({ url: `${Request.requestMapping.userInfo}/${userID}` });
    if (data) {
      this.setState({ profileActivated: data.active });
    }
  };

  getUserObject = async () => {
    let userID = await this.props.getUserID();
    this.setState({ userID: userID });
    this.getFullProfile(userID);
  };

  editLoginData = async event => {
    this.setState({ saving: 'editLoginInfo' });
    event.preventDefault();

    this.newPassword.setError('');
    this.newPasswordRepeat.setError('');
    let email = event.target.email.value;
    let old_password = event.target.old_password.value;
    let new_password = event.target.new_password.value;
    let new_password_repeat = event.target.new_password_repeat.value;

    let scrollIntoView = false;

    if (new_password && new_password.length < 8) {
      this.newPassword.setError('Password must be 8 characters or more.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (new_password && !/[A-Z]/.test(new_password)) {
      this.newPassword.setError('Password must contain at least one capital letter.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (new_password && !/[0-9]/.test(new_password)) {
      this.newPassword.setError('Password must contain at least one number.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (new_password && !/[!-\/:-@[-`{-~]/.test(new_password)) {
      this.newPassword.setError('Password must contain at least one special character.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (new_password && new_password !== new_password_repeat) {
      this.newPasswordRepeat.setError("Passwords don't match", scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (new_password && new_password === old_password) {
      this.newPasswordRepeat.setError(
        'Current password and new password cannot be exactly the same. Please provide a different password.',
        scrollIntoView
      );

      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (scrollIntoView) {
      this.setState({ saving: '' });
      return;
    }

    let userID = await this.props.getUserID();

    let loginDataPayload = {
      id: userID,
      username: email,
      password: old_password,
      newPassword: new_password,
    };
    let { data, error } = await Request.patch({
      url: Request.requestMapping.register,
      data: loginDataPayload,
    });
    this.setState({ saving: '', editLoginInfo: !this.state.editLoginInfo });
    if (data) {
      console.log(data);
    }
    if (error) {
      if (error.message === 'No User') {
        this.oldPassword.setError('Wrong password');
      }
    }
  };
  editPersonalData = async event => {
    event.preventDefault();

    this.first_name.setError('', true);
    this.phone.setError('', true);
    this.middle_name.setError('', true);
    this.mobile.setError('', true);
    this.last_name.setError('', true);
    this.gender.setError('', true);
    this.country_code.setError('', true);
    this.dob.setError('', true);
    this.education.setError('', true);
    this.city.setError('', true);
    this.zip.setError('', true);
    this.employmentStatus.setError('', true);

    if (this.stateRef) {
      this.stateRef.setError('', true);
    } else {
      this.state_input.setError('', true);
    }

    let scrollIntoView = false;

    let first_name = event.target.first_name.value;
    if (!first_name) {
      this.first_name.setError('Please enter your first name.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    let last_name = event.target.last_name.value;
    if (!last_name) {
      this.last_name.setError('Please enter your last name.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    let country_code = this.country_code.getData();
    if (!country_code) {
      this.country_code.setError('Please choose your country of residence.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let city = event.target.city.value;
    if (!city) {
      this.city.setError('Please enter your city / town of residence.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    let zip = event.target.zip.value;
    if (!zip) {
      this.zip.setError('Please enter your zip / postal code.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let phone = event.target.phone.value;
    if (!phone) {
      this.phone.setError('Please enter your phone number.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let mobile = event.target.mobile.value;
    if (!mobile) {
      this.mobile.setError('Please enter your mobile phone number.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    // let dob = event.target.dob.value;
    // if (!dob || moment(dob).year() > moment().year() || +moment(dob).year() < 1910) {
    //   this.dob.setError('Please enter a valid date of birth.', scrollIntoView);
    //   if (!scrollIntoView) {
    //     scrollIntoView = !scrollIntoView;
    //   }
    // }

    let dob = this.dob.getData();

    let gender = this.gender.getData();
    if (!gender) {
      this.gender.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let education = this.education.getData();
    if (!education) {
      this.education.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let middle_name = event.target.middle_name.value;
    let stateName = null;
    if (this.stateRef) {
      const stateObj = this.stateRef.getData();
      if (stateObj) {
        stateName = stateObj.abbreviation;
      }
    } else {
      stateName = event.target.state_input.value;
    }
    if (!stateName) {
      if (this.stateRef) {
        this.stateRef.setError('Please enter your state of residence.');
        if (!scrollIntoView) {
          scrollIntoView = !scrollIntoView;
        }
      } else {
        this.state_input.setError(
          'Please enter your state / province of residence. If not applicable, please write in NA.'
        );
        if (!scrollIntoView) {
          scrollIntoView = !scrollIntoView;
        }
      }
    }

    let email = this.state.userEmail ? this.state.userEmail : await this.props.getEmail();
    if (!email) {
      this.emailRef.setError('Please enter a valid email address.');
    }

    let employmentStatus = this.employmentStatus.getData() ? this.employmentStatus.getData().value : undefined;
    if (!employmentStatus) {
      this.employmentStatus.setError('Please select one option.', true);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (scrollIntoView) {
      this.setState({ saving: '' });
      return;
    }

    this.setState({ saving: 'editPersonalData' });

    let personalDataPayload = {
      candidateInfo: {
        id: this.state.personalInfo ? this.state.personalInfo.id : null,
        firstName: first_name,
        middleName: middle_name,
        lastName: last_name,
        dob: dob,
        gender: gender.value,
        education: education.value,
        candidate: this.state.candidate,
      },
      candidateContact: {
        id: this.state.contactInfo ? this.state.contactInfo.id : null,
        city: city,
        country_code: country_code ? country_code.id : '',
        state: stateName,
        zip: zip,
        phone: phone,
        mobile: mobile,
        candidate: this.state.candidate,
        email: email,
      },
    };

    let professionalDataPayload = {
      id: this.state.professionalInfo ? this.state.professionalInfo.id : null,
      employmentStatus: employmentStatus,
      function: this.state.professionalInfo ? this.state.professionalInfo.function : null,
      seniority: this.state.professionalInfo ? this.state.professionalInfo.seniority : null,
      employmentStartDate: this.state.professionalInfo ? this.state.professionalInfo.employmentStartDate : null,
      jobTitle: this.state.professionalInfo ? this.state.professionalInfo.jobTitle : null,
      candidate: this.state.candidate,
    };

    let professionalInfoResponse = await Request.post({
      url: Request.requestMapping.professionalInfo,
      data: professionalDataPayload,
    });
    if (professionalInfoResponse.data) {
      this.setState({ professionalInfo: professionalInfoResponse.data });
    }

    let { data, error } = await Request.post({
      url: Request.requestMapping.personalInfo,
      data: personalDataPayload,
    });
    this.setState({ saving: '', editPersonalInfo: false });

    if (data) {
      this.setState({ contactInfo: data.candidateContact, personalInfo: data.candidateInfo });
    }
  };

  editProfessionalData = async event => {
    event.preventDefault();

    this.seniority.setError('', true);
    this.proffession.setError('', true);
    this.job_title.setError('', true);
    this.start_date.setError('', true);

    let scrollIntoView = false;

    let proffession = this.proffession.getData();
    if (!proffession) {
      this.proffession.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    let seniority = this.seniority.getData();
    if (!seniority) {
      this.seniority.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    // let start_date = event.target.start_date.value;
    // if (
    //   !start_date ||
    //   moment(event.target.start_date.value).year() > moment().year() ||
    //   moment(event.target.start_date.value).year() < 1910
    // ) {
    //   this.start_date.setError('Please enter a valid start date.', scrollIntoView);
    //   if (!scrollIntoView) {
    //     scrollIntoView = !scrollIntoView;
    //   }
    // }

    let start_date = this.start_date.getData();

    let job_title = event.target.job_title.value;
    if (!job_title) {
      this.job_title.setError('Please enter your current job title.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    // let job_title = this.jobTitle.getData();

    if (scrollIntoView) {
      return;
    }
    this.setState({ saving: 'editProfessionalData' });

    let professionalDataPayload = {
      id: this.state.professionalInfo ? this.state.professionalInfo.id : null,
      employmentStatus: this.state.professionalInfo.employmentStatus,
      function: proffession ? proffession.id : null,
      seniority: seniority ? seniority.value : null,
      employmentStartDate: start_date,
      jobTitle: job_title,
      candidate: this.state.candidate,
    };
    let { data, error } = await Request.post({
      url: Request.requestMapping.professionalInfo,
      data: professionalDataPayload,
    });

    this.setState({ saving: '', editProfessionalInfo: false });
    if (data) {
      this.setState({ professionalInfo: data });
    }
  };

  editCompanyData = async event => {
    event.preventDefault();
    this.companyName.setError('');
    this.company_industry.setError('');
    this.employeeRange.setError('');
    this.companyRevenue.setError('');

    let scrollIntoView = false;

    let company = this.companyName.getData();
    if (!company) {
      this.companyName.setError('Please enter your current company name.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    let companyName = _.isObject(company) ? company : { id: null, name: company };

    let company_industry = this.company_industry.getData();
    if (!company_industry) {
      this.company_industry.setError(`Please select one option.`, scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    let employeeRange = this.employeeRange.getData();
    if (!employeeRange) {
      this.employeeRange.setError('Please select one option.', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }
    let companyRevenue = this.companyRevenue.getData();
    if (!companyRevenue) {
      this.companyRevenue.setError('Please select a company revenue range', scrollIntoView);
      if (!scrollIntoView) {
        scrollIntoView = !scrollIntoView;
      }
    }

    if (scrollIntoView) {
      return;
    }
    this.setState({ saving: 'editCompanyData' });

    let companyDataPayload = {
      id: this.state.companyInfo ? this.state.companyInfo.id : null,
      company: companyName,
      industry: company_industry,
      companyRevenueLower: companyRevenue ? companyRevenue.value[0] : null,
      companyRevenueUpper: companyRevenue ? companyRevenue.value[0] : null,
      employeeRangeLower: companyRevenue ? employeeRange.value[0] : null,
      employeeRangeUpper: companyRevenue ? employeeRange.value[1] : null,
      candidate: this.state.candidate,
    };
    let { data, error } = await Request.post({
      url: Request.requestMapping.companyInfo,
      data: companyDataPayload,
    });
    this.setState({ saving: '', editCompanyInfo: false });
    if (data) {
      this.setState({ companyInfo: data });
    }
  };

  sendActivationEmail = async () => {
    let intialPayload = {
      username: this.state.contactInfo.email,
    };

    let { data, error } = await Request.post({
      url: Request.requestMapping.activationEmail,
      data: intialPayload,
    });
  };

  render() {
    return (
      <div className="profile-page-wrapper">
        <div className="divider" />
        <section className="section-block">
          <h2 className="section-heading">Hello {this.props.userName}</h2>
          {!this.state.profileActivated && (
            <div style={{ dusplay: 'flex' }}>
              <h3 className="activation-heading">
                Your profile hasn't been verified yet! Please verify your profile with the email sent to your email
                address.
              </h3>
              <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center', marginBottom: '25px' }}>
                <THButton onClick={this.sendActivationEmail} text="RESEND ACTIVATION EMAIL" />
              </div>
            </div>
          )}

          <p style={{ fontSize: '18px', fontWeight: '600' }}>
            Welcome to your profile page. We use this information to match your profile to a potential research study.
            Please focus on having the most up to date information, as it will increase the chances for a successful
            match.
          </p>
          <p style={{ fontSize: '18px', fontWeight: '600' }}>
            Your profile is not publicly accessible and remains confidential.
          </p>
        </section>
        <div className="divider"> </div>

        <section
          className={
            this.state.saving && this.state.saving === 'editLoginInfo' ? 'section-block rainbow' : 'section-block'
          }
        >
          <h3 className="section-heading">Login Information</h3>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <THButton
              onClick={() =>
                this.setState({
                  editLoginInfo: !this.state.editLoginInfo,
                  editPersonalInfo: false,
                  editProfessionalInfo: false,
                  editCompanyInfo: false,
                })
              }
              toggleState={!this.state.editLoginInfo}
              toggledText="UPDATE MODE ACTIVE"
              text="EDIT / UPDATE"
            />
          </div>
          <form onSubmit={this.editLoginData} className="inputs-container">
            <div>
              <THInput
                // required
                disabled={!this.state.editLoginInfo}
                ref={ref => (this.emailRef = ref)}
                type="email"
                name={'email'}
                initialLoad={this.state.loading}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Email Address / Username</b>}
                defaultValue={
                  this.state.contactInfo
                    ? this.state.contactInfo.email
                    : this.state.userEmail
                    ? this.state.userEmail
                    : ''
                }
              />
            </div>
            <div>
              <THInput
                // required
                disabled={!this.state.editLoginInfo}
                ref={ref => (this.newPassword = ref)}
                type="password"
                name={'new_password'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>New password</b>}
                defaultValue=""
              />
            </div>
            <div>
              <THInput
                // required
                disabled={!this.state.editLoginInfo}
                ref={ref => (this.oldPassword = ref)}
                type="password"
                name={'old_password'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Current password</b>}
                defaultValue=""
              />
            </div>
            <div>
              <THInput
                // required
                disabled={!this.state.editLoginInfo}
                ref={ref => (this.newPasswordRepeat = ref)}
                type="password"
                name={'new_password_repeat'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Confirm new password</b>}
                defaultValue=""
              />
            </div>
            <div></div>
            <div className={this.state.editLoginInfo ? 'save-actionbar' : 'save-actionbar hidden'}>
              <THButton type="submit" disabled={!this.state.editLoginInfo} text="SAVE" />
            </div>
          </form>
        </section>
        <div className="divider"> </div>
        <span></span>
        <section
          className={
            this.state.saving && this.state.saving === 'editPersonalData' ? 'section-block rainbow' : 'section-block'
          }
        >
          <h3 className="section-heading">Personal INFORMATION</h3>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <THButton
              onClick={() =>
                this.setState({
                  editPersonalInfo: !this.state.editPersonalInfo,
                  editLoginInfo: false,
                  editProfessionalInfo: false,
                  editCompanyInfo: false,
                })
              }
              toggleState={!this.state.editPersonalInfo}
              toggledText="UPDATE MODE ACTIVE"
              text="EDIT / UPDATE"
            />
          </div>
          <form onSubmit={this.editPersonalData} className="inputs-container">
            <div className="first-column-input">
              <THInput
                className="first-column-input"
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.first_name = ref)}
                type="text"
                name={'first_name'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>First name</b>}
                defaultValue={this.state.personalInfo ? this.state.personalInfo.firstName : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="first-column-input">
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.middle_name = ref)}
                type="text"
                name={'middle_name'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Middle name</b>}
                defaultValue={this.state.personalInfo ? this.state.personalInfo.middleName : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="first-column-input">
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.last_name = ref)}
                type="text"
                className="first-column-input"
                name={'last_name'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Last name</b>}
                defaultValue={this.state.personalInfo ? this.state.personalInfo.lastName : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="first-column-input">
              <THSelect
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.country_code = ref)}
                name={'country_code'}
                className="first-column-input"
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Country</b>}
                note={''}
                dataSource={this.getCountries}
                handleChange={value => this.setState({ selectedCountry: value ? value.id : '' })}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue="id"
                defaultValue={this.state.contactInfo ? this.state.contactInfo.country_code : ''}
              />
            </div>
            <div className="first-column-input">
              {this.state.selectedCountry && this.state.selectedCountry === 194 && (
                <THSelect
                  disabled={!this.state.editPersonalInfo}
                  ref={ref => (this.stateRef = ref)}
                  name={'state'}
                  className="first-column-input"
                  label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>State/Province</b>}
                  note={''}
                  dataSource={this.getStates}
                  displayName={'abbreviation'}
                  initialLoad={this.state.loading}
                  comparisonValue="abbreviation"
                  defaultValue={this.state.contactInfo ? this.state.contactInfo.state : ''}
                />
              )}

              {(this.state.selectedCountry === '' || this.state.selectedCountry !== 194) && (
                <THInput
                  disabled={!this.state.editPersonalInfo}
                  ref={ref => (this.state_input = ref)}
                  name={'state_input'}
                  className="first-column-input"
                  label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>State/Province</b>}
                  initialLoad={this.state.loading}
                  defaultValue={this.state.contactInfo ? this.state.contactInfo.state : ''}
                />
              )}
            </div>
            <div className="first-column-input">
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.city = ref)}
                type="text"
                className="first-column-input"
                name={'city'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>City/Town</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.city : ''}
                initialLoad={this.state.loading}
              />
            </div>
            <div className="second-column-input" style={{ gridRow: '6' }}>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.zip = ref)}
                type="text"
                className="second-column-input"
                name={'zip'}
                initialLoad={this.state.loading}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>ZIP/Postal code</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.zip : ''}
              />
            </div>

            <div className="second-column-input" style={{ gridRow: '1' }}>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.phone = ref)}
                type="tel"
                name={'phone'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Phone number</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.phone : ''}
                initialLoad={this.state.loading}
              />
            </div>

            <div className="second-column-input" style={{ gridRow: '2' }}>
              <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.mobile = ref)}
                type="tel"
                className="second-column-input"
                name={'mobile'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Mobile phone number</b>}
                defaultValue={this.state.contactInfo ? this.state.contactInfo.mobile : ''}
                initialLoad={this.state.loading}
              />
            </div>

            {/* SELECT */}
            <div className="second-column-input" style={{ gridRow: '3' }}>
              <THSelect
                disabled={!this.state.editPersonalInfo}
                required
                ref={ref => (this.gender = ref)}
                className="second-column-input"
                name={'gender'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Gender</b>}
                note={''}
                dataSource={this.props.getGenders}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue="value"
                defaultValue={this.state.personalInfo ? this.state.personalInfo.gender : ''}
              />
            </div>
            {/* SELECT */}

            {/* SELECT */}

            {/* SELECT */}

            <div className="second-column-input" style={{ gridRow: '4' }}>
              <THSimpleDatePicker
                disabled={!this.state.editPersonalInfo}
                className="second-column-input"
                ref={ref => (this.dob = ref)}
                name={'dob'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Date of birth</b>}
                initialLoad={this.state.loading}
                defaultValue={
                  this.state.personalInfo && this.state.personalInfo.dob
                    ? moment(this.state.personalInfo.dob).format('YYYY-MM-DD')
                    : ''
                }
              ></THSimpleDatePicker>
              {/* <THInput
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.dob = ref)}
                type="date"
                className="second-column-input"
                name={'dob'}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Date of birth</b>}
                initialLoad={this.state.loading}
                defaultValue={this.state.personalInfo ? moment(this.state.personalInfo.dob).format('YYYY-MM-DD') : ''}
              /> */}
            </div>

            {/* SELECT OR TEXT */}

            {/* SELECT OR TEXT */}

            {/* SELECT */}
            <div className="second-column-input" style={{ gridRow: '5' }}>
              <THSelect
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.education = ref)}
                name={'education'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Highest level of education</b>}
                note={''}
                dataSource={this.props.getEducation}
                initialLoad={this.state.loading}
                className="second-column-input"
                displayName={'name'}
                comparisonValue="value"
                defaultValue={this.state.personalInfo ? this.state.personalInfo.education : ''}
              />
            </div>
            {/* SELECT */}
            <div className="first-column-input" style={{ gridRow: '7' }}>
              <THSelect
                disabled={!this.state.editPersonalInfo}
                ref={ref => (this.employmentStatus = ref)}
                className="first-column-input"
                name={'employment_status'}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Current employment status</b>}
                note={''}
                dataSource={this.props.getEmployment}
                initialLoad={this.state.loading}
                displayName={'name'}
                comparisonValue="value"
                defaultValue={
                  this.state.professionalInfo && this.state.professionalInfo.employmentStatus
                    ? this.state.professionalInfo.employmentStatus
                    : ''
                }
              />
            </div>
            {/* SELECT */}

            <div></div>

            <div></div>
            <div className={this.state.editPersonalInfo ? 'save-actionbar' : 'save-actionbar hidden'}>
              <THButton type="submit" text="SAVE" />
            </div>
          </form>
        </section>
        {this.state.professionalInfo && this.state.professionalInfo.employmentStatus < 5 && (
          <React.Fragment>
            <div className="divider"> </div>
            <section
              className={
                this.state.saving && this.state.saving === 'editProfessionalData'
                  ? 'section-block rainbow'
                  : 'section-block'
              }
            >
              <h3 className="section-heading">Professional INFORMATION</h3>
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <THButton
                  onClick={() =>
                    this.setState({
                      editProfessionalInfo: !this.state.editProfessionalInfo,
                      editLoginInfo: false,
                      editPersonalInfo: false,
                      editCompanyInfo: false,
                    })
                  }
                  toggleState={!this.state.editProfessionalInfo}
                  toggledText="UPDATE MODE ACTIVE"
                  text="EDIT / UPDATE"
                />
              </div>
              <form onSubmit={this.editProfessionalData} className="inputs-container">
                {/* SELECT */}
                <div>
                  <THSelect
                    disabled={!this.state.editProfessionalInfo}
                    ref={ref => (this.proffession = ref)}
                    name={'industry'}
                    label={() => (
                      <b style={{ fontSize: '15px', fontWeight: '600' }}>
                        Your department / functional area at your current company
                      </b>
                    )}
                    comparisonValue={'id'}
                    dataSource={this.props.getFunctions}
                    displayName={'name'}
                    initialLoad={this.state.loading}
                    defaultValue={this.state.professionalInfo ? this.state.professionalInfo.function : ''}
                  />
                </div>
                <div>
                  <THSelect
                    disabled={!this.state.editProfessionalInfo}
                    ref={ref => (this.seniority = ref)}
                    name={'industry'}
                    comparisonValue={'value'}
                    label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Your current seniority level</b>}
                    dataSource={this.props.getSeniority}
                    displayName={'name'}
                    initialLoad={this.state.loading}
                    defaultValue={this.state.professionalInfo ? this.state.professionalInfo.seniority : ''}
                  />
                </div>
                <div>
                  <THSimpleDatePicker
                    disabled={!this.state.editProfessionalInfo}
                    ref={ref => (this.start_date = ref)}
                    name={'start_date'}
                    label={() => (
                      <b style={{ fontSize: '15px', fontWeight: '600' }}>
                        Start date of your engagement in your current role
                      </b>
                    )}
                    initialLoad={this.state.loading}
                    defaultValue={
                      this.state.professionalInfo && this.state.professionalInfo.employmentStartDate
                        ? moment(this.state.professionalInfo.employmentStartDate).format('YYYY-MM-DD')
                        : ''
                    }
                  ></THSimpleDatePicker>

                  {/* <THInput
                    disabled={!this.state.editProfessionalInfo}
                    ref={ref => (this.start_date = ref)}
                    type="date"
                    name={'start_date'}
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    label={() => (
                      <b style={{ fontSize: '15px', fontWeight: '600' }}>
                        Start date of your engagement in your current role
                      </b>
                    )}
                    initialLoad={this.state.loading}
                    defaultValue={
                      this.state.professionalInfo
                        ? moment(this.state.professionalInfo.employmentStartDate).format('YYYY-MM-DD')
                        : moment()
                    }
                  /> */}
                </div>
                <div>
                  {/* <Autocomplete
                disabled={!this.state.editProfessionalInfo}
                filterField={'name'}
                displayField={'name'}
                placeholder={''}
                ref={ref => (this.jobTitle = ref)}
                label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Your exact current job title</b>}
                dataSource={this.props.getCompanies}
                note={' '}
                initialLoad={this.state.loading}
                defaultValue={this.state.jobTitle ? this.state.professionalInfo.jobTitle : ''}
              /> */}

                  <THInput
                    disabled={!this.state.editProfessionalInfo}
                    ref={ref => (this.job_title = ref)}
                    name={'job_title'}
                    label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Your exact current job title</b>}
                    initialLoad={this.state.loading}
                    defaultValue={this.state.professionalInfo ? this.state.professionalInfo.jobTitle : ''}
                  />
                </div>
                <div></div>
                <div className={this.state.editProfessionalInfo ? 'save-actionbar' : 'save-actionbar hidden'}>
                  <THButton type="submit" disabled={!this.state.editProfessionalInfo} text="SAVE" />
                </div>
              </form>
            </section>
          </React.Fragment>
        )}

        {this.state.professionalInfo && this.state.professionalInfo.employmentStatus < 5 && (
          <React.Fragment>
            <div className="divider" />
            <section
              className={
                this.state.saving && this.state.saving === 'editCompanyData' ? 'section-block rainbow' : 'section-block'
              }
            >
              <h3 className="section-heading">Company INFORMATION</h3>
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <THButton
                  onClick={() =>
                    this.setState({
                      editCompanyInfo: !this.state.editCompanyInfo,
                      editLoginInfo: false,
                      editPersonalInfo: false,
                      editProfessionalInfo: false,
                    })
                  }
                  toggleState={!this.state.editCompanyInfo}
                  toggledText="UPDATE MODE ACTIVE"
                  text="EDIT / UPDATE"
                />
              </div>
              <form onSubmit={this.editCompanyData} className="inputs-container">
                <div>
                  <Autocomplete
                    disabled={!this.state.editCompanyInfo}
                    filterField={'name'}
                    displayField={'name'}
                    placeholder={''}
                    ref={ref => (this.companyName = ref)}
                    label={() => <b style={{ fontSize: '15px', fontWeight: '600' }}>Your current company name</b>}
                    dataSource={this.props.getCompanies}
                    note={' '}
                    initialLoad={this.state.loading}
                    defaultValue={this.state.companyInfo ? this.state.companyInfo.company : ''}
                  />
                </div>
                <div>
                  <THSelect
                    disabled={!this.state.editCompanyInfo}
                    ref={ref => (this.company_industry = ref)}
                    name={'company_industry'}
                    comparisonValue={'id'}
                    label={() => (
                      <b style={{ fontSize: '15px', fontWeight: '600' }}>Your current company's primary industry</b>
                    )}
                    note={''}
                    dataSource={this.props.getIndustries}
                    initialLoad={this.state.loading}
                    displayName={'name'}
                    defaultValue={
                      this.state.companyInfo && this.state.companyInfo.industry
                        ? this.state.companyInfo.industry.id
                        : null
                    }
                  />
                </div>
                <div>
                  <THSelect
                    disabled={!this.state.editCompanyInfo}
                    ref={ref => (this.employeeRange = ref)}
                    name={'company_industry'}
                    label={() => (
                      <b style={{ fontSize: '15px', fontWeight: '600' }}>Number of full-time employees worldwide</b>
                    )}
                    note={''}
                    dataSource={this.props.getEmployees}
                    initialLoad={this.state.loading}
                    displayName={'name'}
                    comparisonValue={'value[0]'}
                    defaultValue={this.state.companyInfo ? this.state.companyInfo.employeeRangeLower : ''}
                  />
                </div>
                <div>
                  <THSelect
                    disabled={!this.state.editCompanyInfo}
                    ref={ref => (this.companyRevenue = ref)}
                    name={'company_industry'}
                    label={() => (
                      <b style={{ fontSize: '15px', fontWeight: '600' }}>Your company's total revenue in 2018 in US$</b>
                    )}
                    note={''}
                    dataSource={this.props.getRevenue}
                    initialLoad={this.state.loading}
                    displayName={'name'}
                    comparisonValue={'value[0]'}
                    defaultValue={this.state.companyInfo ? this.state.companyInfo.companyRevenueLower : ''}
                  />
                </div>
                <div></div>
                <div className={this.state.editCompanyInfo ? 'save-actionbar' : 'save-actionbar hidden'}>
                  <THButton type="submit" disabled={!this.state.editCompanyInfo} text="SAVE" />
                </div>
              </form>
            </section>
          </React.Fragment>
        )}
        <div className="divider" />
        <p style={{ margin: 'auto', textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>
          If you wish to opt out of research study opportunities, have questions, or feedback write to us at:
          <span>
            <a href="mailto:recruiter@targethype.com" style={{ paddingLeft: '8px', fontSize: '18px' }}>
              recruiter@targethype.com
            </a>
          </span>
        </p>
      </div>
    );
  }
}

export default withUtilities(withUserInfo(ProfilePage));
