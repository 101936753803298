import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import "./home.scss";

class HomePage extends React.Component {
  render() {
    return (
      <div className="homepage-wrapper">
        <div className="homepage-container">
          <section>
            <div className="hero">
              <div className="hero-elements">
                <h1 className="hero-headline">
                  <b>Connecting</b> people with <b>insights</b> to research
                  firms that <b>value</b> them.
                </h1>
                <a
                  href="https://mailchi.mp/targethype.com/register"
                  className="register-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  REGISTER WITH TARGET HYPE
                </a>
              </div>
            </div>
          </section>
          <section>
            <p className="homepage-b2b">
              We recruit participants for B2B qualitative market research
              studies, in partnership with global market research companies.
            </p>
            <p className="homepage-subscript">
              Target Hype is grounded in values from a time when a handshake
              meant something, and people were as good as their word. <br />
            </p>
            <p className="homepage-subscript">
              But Target Hype is also a 21<sup>st</sup> century company. Global.
              Technology-driven. Connected.
            </p>
            <p className="homepage-subscript">
              Target Hype merges the efficiencies of global online recruiting
              with the human touch of a company deeply committed to delivering
              on its promises with integrity and transparency.
            </p>
          </section>
        </div>
      </div>
    );
  }
}

export default HomePage;
