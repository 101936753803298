import React from 'react';
import { NavLink } from 'react-router-dom';
import thLogo from '../../../assets/thLogo.png';
import smallLogo from '../../../assets/footerLogo.png';

import Icon from '../icon/icon';
// import { ReactComponent as Linkedin } from '../../../assets/linkedin.svg';
// import { ReactComponent as Facebook } from '../../../assets/facebook.svg';
// import { ReactComponent as Skype } from '../../../assets/skype.svg';

import './footer.scss';
export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <div className="content">
          <div className="info-container">
            <span className="small-logo-container">
              <img draggable="false" className="small-logo" src={smallLogo} alt={'Target Hype'} />
            </span>
            <span className="address-info">
              Target Hype, #3, Rankeova str. 11000 Belgrade, Serbia <br />
              Reg. No: 21498165 • VAT No: 111535196
            </span>
          </div>
          <div />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="contact-container">
              <div className="contact">
                <div className="contact-label">CONTACT:</div>
                <a href="mailto:recruiter@targethype.com?Subject=TargetHype Inquiry" target="_top">
                  recruiter@targethype.com
                </a>
              </div>
            </div>
            <div className="social">
              <a href="https://www.linkedin.com/company/target-hype/" target="blank">
                <Icon name="linkedin" width="35" height="35" className="social-link" />
              </a>
              <a href="https://www.facebook.com/Target-Hype-111029540269997/" target="blank">
                <Icon name="facebook" width="35" height="35" className="social-link" />
              </a>
              {/* <a href="https://www.linkedin.com/company/target-hype/about/" target="blank">
                <Icon name="skype" width="35" height="35" className="social-link" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="legal">
          <div className="copyright-info">Copyright © 2019 Targethype. All rights reserved.</div>
          {/* <div /> */}
          <div className="guides-and-privacy">
            {/* <a className="legal-link" href="./">
              Guides
            </a> */}
            <a className="legal-link" href="./terms-and-conditions">
              Terms of use
            </a>
            <a className="legal-link" href="./privacy-policy">
              Privacy & Cookie policy
            </a>
          </div>
        </div>
      </div>
    );
  }
}
