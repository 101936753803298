import React, { Component } from "react";

import "./styles.scss";
import { CSSTransition } from "react-transition-group";

class THDialog extends Component {
  render() {
    if (this.props.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return (
      <React.Fragment>
        {this.props.open && (
          <CSSTransition
            transitionName="dialog"
            transitionAppear={true}
            transitionAppearTimeout={1}
            transitionEnter={false}
            transitionLeave={false}
          >
            <div className="dialog-wrapper" onClick={this.props.close}>
              <div
                className={
                  !this.props.bottomSnack
                    ? "dialog-container"
                    : "dialog-container-bottom"
                }
                style={{
                  paddingBottom: this.props.actions ? "60px" : "0px",
                  width: this.props.fullWidthDialogContent ? "100vw" : "",
                  justifyContent: "center",
                }}
                onClick={(event) => event.stopPropagation()}
              >
                <div className="dialog-container-header">
                  {this.props.title}
                </div>
                <div className="dialog-container-content">
                  {this.props.children}
                </div>
                {this.props.actions && (
                  <React.Fragment>
                    <div className="dialog-container-actions">
                      {this.props.actions()}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </React.Fragment>
    );
  }
}

export { THDialog };
