import React from 'react';

import './progress-bar.scss';
export default class ProgressBar extends React.Component {
  state = {};
  render() {
    return (
      <div className="main-progress-container">
        <div className="progress-bar-container">
          <div className="progress-graphic">
            <div className="progress-empty" style={{ width: this.props.percent }}>
              {' '}
            </div>
          </div>
          <div className="progress-percent">{this.props.percent}</div>
        </div>
      </div>
    );
  }
}
