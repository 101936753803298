import React from 'react';
import _ from 'lodash';

import './select.scss';

export default class THSelect extends React.Component {
  static inputId = 0;
  myRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selected: '',
      errorMessage: '',
      actualValue: null,
    };
  }

  scrollIntoView = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  };
  setSelected = () => {
    let newSelected = null;
    if (_.isFunction(this.props.comparisonValue)) {
      newSelected = this.state.options.find((item, index) => {
        return this.props.comparisonValue(item, this.props.defaultValue);
      });
    } else {
      newSelected = this.state.options.find((item, index) => {
        let propVal = this.props.defaultValue;
        let itemVal = this.props.comparisonValue ? _.get(item, this.props.comparisonValue) : index;
        return propVal === itemVal;
      });
    }
    if (this.state.selected !== newSelected) {
      this.setState({
        selected: this.props.comparisonValue ? _.get(newSelected, this.props.comparisonValue) : this.props.defaultValue,
        actualValue: newSelected,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setSelected();
    }
  }

  async componentDidMount() {
    if (_.isFunction(this.props.dataSource)) {
      let { data, error } = _.isFunction(this.props.dataSource) ? await this.props.dataSource() : this.props.dataSource;
      if (data) {
        this.setState({ options: data, value: this.props.defaultValue });
        this.setSelected();
      } else {
        throw error;
      }
    } else {
      this.setState({ options: this.props.dataSource, value: this.props.defaultValue }, () => this.setSelected());
    }
  }

  getData() {
    return this.state.actualValue;
  }

  setError = (errorMessage, dontScrollIntoView) => {
    this.setState({ errorMessage: errorMessage });
    if (dontScrollIntoView) {
      this.scrollIntoView();
    }
  };

  handleChange = event => {
    let filterVal = event.target.value;

    let selectedVal = this.props.comparisonValue
      ? this.state.options.find(item => _.get(item, this.props.comparisonValue) == filterVal)
      : this.state.options[filterVal];

    if (this.props.handleChange) {
      this.props.handleChange(selectedVal);
    }

    this.setState({
      selected: this.props.comparisonValue ? _.get(selectedVal, this.props.comparisonValue) : filterVal,
      actualValue: selectedVal,
    });
    console.log('value changed', selectedVal);
  };
  renderOption = (item, index) => {
    const currentlySelected = this.state.selected;
    const displayVal = this.props.displayName ? _.get(item, this.props.displayName) : item;
    const actualVal = this.props.comparisonValue ? _.get(item, this.props.comparisonValue) : index;

    return (
      <option
        className={currentlySelected === displayVal ? 'option-bold' : 'option'}
        key={'select-' + (this.props.name ? this.props.name + '-' : '-') + index}
        value={actualVal}
      >
        {displayVal}
      </option>
    );
  };

  renderNote = () => {
    if (_.isFunction(this.props.note)) {
      return this.props.note();
    } else {
      return (
        <div>
          <p className="select-note">{this.props.note}</p>
        </div>
      );
    }
  };
  renderPlaceholder() {
    return <div className="placeholder"></div>;
  }
  renderSelect = () => {
    return (
      <select disabled={this.props.disabled} value={this.state.selected} onChange={this.handleChange}>
        <option
          disabled={this.props.placeholderOption}
          selected={this.props.placeholderOption}
          hidden={this.props.placeholderOption}
          className={'option'}
          key={'select-' + (this.props.name ? this.props.name + '-' : '-') + '-1'}
          value={''}
        >
          {this.props.placeholderOption ? this.props.placeholderOption : ''}
        </option>
        {this.state.options &&
          this.state.options.length > 0 &&
          this.state.options.map((item, index) => this.renderOption(item, index))}
      </select>
    );
  };

  render() {
    return (
      <div ref={this.myRef} className="select-container">
        {_.isFunction(this.props.label) ? (
          this.props.label()
        ) : (
          <label className="label" htmlFor={this.props.name}>
            {this.props.required ? '* ' + this.props.label : this.props.label}
          </label>
        )}

        {this.props.note && this.renderNote()}
        {this.props.initialLoad !== undefined && this.props.initialLoad && (
          <React.Fragment>{this.renderPlaceholder()}</React.Fragment>
        )}

        {(this.props.initialLoad === undefined || this.props.initialLoad === false) && (
          <React.Fragment>{this.renderSelect()}</React.Fragment>
        )}

        {this.state.errorMessage && <span className="error">{this.state.errorMessage}</span>}
      </div>
    );
  }
}
