import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';
class TermsAndConditionsPage extends React.Component {
  render() {
    return (
      <div className="terms-and-conditions-wrapper">
        <div className="divider"> </div>
        <div className="th-difference-container">
          <h1>TERMS AND CONDITIONS</h1>

          <ul className="th-differences">
            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    This website is provided on behalf of Target Hype doo, a limited company, whose registered office is
                    at #3, Rankeova str. 11000 Belgrade, Serbia. Reg. No: 21498165, VAT No: 111535196.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    Target Hype doo is registered as a limited company by the Serbian Business Registers Agency (BD
                    65545/2019).
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    Please read the details set out below carefully before using this website.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Definitions</span>
                  <span className="th-difference-sub">
                    For the purpose of these Terms and Conditions of Use, ‘you’ or ‘your’ means the person or persons
                    accessing the website and ‘our’, ‘us’ or ‘we’ refers to Target Hype doo.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Terms Of Use</span>
                  <span className="th-difference-sub">
                    By using our website, you are deemed to have agreed to comply with and be bound by these terms and
                    conditions, which in conjunction with our Cookies & Privacy Policy, govern our relationship with you
                    in relation to this website.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    You may only use our website if you agree to these terms and conditions. If you do not agree to
                    them, you must stop using our website.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    Changes may be made to the terms at any time without notice by updating this posting. You agree to
                    review the terms regularly and your continued access to or use of the website will mean you agree to
                    any changes.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Website content</span>
                  <span className="th-difference-sub">
                    The following clauses exclude or limit our legal liability for the website. You should read them
                    carefully.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    Whilst we have taken reasonable steps to ensure the accuracy, currency, availability correctness and
                    completeness of the information contained on the website, information is provided on an “as is”, “as
                    available” basis and we do not give or make any warranty or representation of any kind, whether
                    express or implied. The use of the website is at your sole risk. We will not be liable for any loss
                    or damage whatsoever and howsoever arising as a result of your use of or reliance on the information
                    contained on the website to the maximum extent permitted by law.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    We do not represent or warrant that the website will be available and meet your requirements, that
                    access will be uninterrupted, that there will be no delays, failures, errors or omissions or loss of
                    transmitted information, that no viruses or other contaminating or destructive properties will be
                    transmitted or that no damage will occur to your computer system. You have sole responsibility for
                    adequate protection and back up of data and/or equipment and for undertaking reasonable and
                    appropriate precautions to scan for computer viruses or other destructive properties.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    We make no representations or warranties regarding the accuracy, functionality or performance of any
                    third-party software that may be used in connection with the website.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Third party websites and warranties</span>
                  <span className="th-difference-sub">
                    Where we provide hypertext links to other locations on the Internet, we do so for information
                    purposes only. We are not responsible for the content of any other websites or pages linked to or
                    linking to this website. We have not verified the content of any such websites. Following links to
                    any other websites or pages will be at your own risk and we will not be responsible or liable for
                    any damages or in other way in connection with linking. Links to downloadable software websites are
                    for convenience only and we are not responsible or liable for any difficulties or consequences
                    associated with downloading the software. Use of any downloaded software is governed by the terms of
                    the license agreement, if any, which accompanies or is provided with the software.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">
                    No endorsement or approval of any third parties or their advice, opinions, information, products or
                    services is expressed or implied by any information on our website.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Internet Email</span>
                  <span className="th-difference-sub">
                    Messages sent over the Internet cannot be guaranteed to be completely secure as they are subject to
                    possible interception or loss or possible alteration. We are not responsible for them and will not
                    be liable to you or anyone else for any damages or otherwise in connection with any message sent by
                    you to us or any message sent by us to you over the Internet.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Trade marks and copyright</span>
                  <span className="th-difference-sub">
                    Copyright in the pages screens, information and material in their arrangement included in this
                    website is owned by or licensed to us unless otherwise noted. You may imprint, copy, download or
                    temporarily store extracts from our website for your personal information or when you use our
                    products and services. You must not alter anything. Any other use is prohibited unless you first get
                    our written permission.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-item-bullet">
                  <span className="th-difference-number"></span>
                </span>
                <div className="th-difference-point">
                  <span className="th-difference-head">Governing Law</span>
                  <span className="th-difference-sub">
                    The Terms and Conditions of Use contain the entire agreement between us in connection with this
                    website. The agreement will be governed by and construed in accordance with the laws of Serbia. You
                    agree to submit any dispute arising out of your use of this website or associated services to the
                    exclusive jurisdiction of the Court of Belgrade, Serbia.
                  </span>
                </div>
              </div>
            </li>

            <li>
              <div className="th-difference-item">
                <span className="th-difference-number"></span>
                <div className="th-difference-point">
                  <span></span>
                  <span className="th-difference-sub">Information updated on November 1st 2019</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default TermsAndConditionsPage;
