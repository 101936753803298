import React, { createContext } from 'react';
import Request from '../../../request';
export const UtilityContext = createContext({
  userID: -1,
});

export class UtilityProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getEmployment: this.getEmployment,
      getEducation: this.getEducation,
      getGenders: this.getGenders,
      getFunctions: this.getFunctions,
      getIndustries: this.getIndustries,
      getCompanies: this.getCompanies,
      getCountry: this.getCountry,
      getSeniority: this.getSeniority,
      getEmployees: this.getEmployees,
      getRevenue: this.getRevenue,
    };
  }

  getRevenue = () => {
    return {
      data: [
        { name: 'Less than $500,000', value: [0, 5] },
        { name: '$500,000 to less than $1 million', value: [5, 10] },
        { name: '$1 million to less than $2 million', value: [10, 20] },
        { name: '$2 million to less than $5 million', value: [20, 50] },
        { name: '$5 million to less than $10 million', value: [50, 100] },
        { name: '$10 million to less than $25 million', value: [100, 250] },
        { name: '$25 million to less than $50 million', value: [250, 500] },
        { name: '$50 million to less than $100 million', value: [500, 1000] },
        { name: '$100 million to less than $250 million', value: [1000, 2500] },
        { name: '$250 million to less than $500 million', value: [2500, 5000] },
        { name: '$500 million to less than $1 billion', value: [5000, 10000] },
        { name: '$1 billion to less than $2 billion', value: [10000, 20000] },
        { name: '$2 billion to less than $3 billion', value: [20000, 30000] },
        { name: '$3 billion to less than $5 billion', value: [30000, 50000] },
        { name: '$5 billion to less than $10 billion', value: [50000, 1000000] },
        { name: 'More than $10 billion', value: [100000, 500000] },
      ],
    };
  };

  getEmployees = () => {
    return {
      data: [
        { name: 'I am the only employee', value: [0, 1] },
        { name: '2 to less than 5', value: [2, 5] },
        { name: '5 to less than 10', value: [5, 10] },
        { name: '10 to less than 25', value: [10, 25] },
        { name: '25 to less than 50', value: [25, 50] },
        { name: '50 to less than 100', value: [50, 100] },
        { name: '100 to less than 250', value: [100, 250] },
        { name: '250 to less than 500', value: [250, 500] },
        { name: '500 to less than 1,000', value: [500, 1000] },
        { name: '1,000 to less than 2,500', value: [1000, 2500] },
        { name: '2,500 to less than 5,000', value: [2500, 5000] },
        { name: '5,000 to less than 10,000', value: [5000, 10000] },
        { name: '10,000 to less than 20,000', value: [10000, 20000] },
        { name: '20,000 to less than 30,000', value: [20000, 30000] },
        { name: '30,000 to less than 40,000', value: [30000, 40000] },
        { name: '40,000 to less than 50,000', value: [40000, 50000] },
        { name: 'More than 50,000', value: [50000, 5000000] },
      ],
    };
  };

  getSeniority = () => {
    return {
      data: [
        {
          value: 1,
          name: 'CEO, President, Chairman, Partner',
        },
        {
          value: 2,
          name: 'Other C-level Executive e.g. COO/CFO/CIO/CTO/CMO etc.',
        },
        {
          value: 3,
          name: 'Vice President e.g. VP/SVP/EVP',
        },
        {
          value: 4,
          name: 'Senior Director / Director',
        },
        {
          value: 5,
          name: 'Senior Manager / Manager',
        },
        {
          value: 6,
          name: 'Senior Professional / Expert',
        },
        {
          value: 7,
          name: 'Individual Contributor / Staff',
        },
        {
          value: 8,
          name: 'Intern / Entry Level',
        },
      ],
    };
  };

  getEmployment = async () => {
    return {
      data: [
        {
          value: 1,
          name: 'Employed full time (more than 30 hours per week)',
        },
        {
          value: 2,
          name: 'Employed part time (less than 30 hours per week)',
        },
        {
          value: 3,
          name: 'Company Founder / Owner',
        },
        {
          value: 4,
          name: 'Consultant / Freelance',
        },
        {
          value: 5,
          name: 'Student',
        },
        {
          value: 6,
          name: 'Unemployed',
        },
        {
          value: 7,
          name: 'Retired',
        },
      ],
    };
  };

  getEducation = async () => {
    return {
      data: [
        {
          value: 1,
          name: 'Some high school, no diploma',
        },
        {
          value: 2,
          name: 'High school graduate, diploma, or GED',
        },
        {
          value: 3,
          name: 'Some college coursework, no degree',
        },
        {
          value: 4,
          name: 'Bachelor’s degree',
        },
        {
          value: 5,
          name: 'Master’s degree',
        },
        {
          value: 6,
          name: 'Doctorate degree',
        },
      ],
    };
  };

  getGenders = async () => {
    return {
      data: [
        {
          value: 1,
          name: 'Male',
        },
        {
          value: 2,
          name: 'Female',
        },
        {
          value: 3,
          name: 'Other',
        },
        {
          value: 0,
          name: 'Prefer not to disclose',
        },
      ],
    };
  };

  getFunctions = async () => {
    return await Request.get({ url: Request.requestMapping.util.functions });
  };

  getIndustries = async () => {
    return await Request.get({ url: Request.requestMapping.util.industries });
  };

  getCompanies = async namePart => {
    return await Request.get({ url: Request.requestMapping.util.companies, params: { name: namePart } });
  };

  getJobTitles = async namePart => {
    return await Request.get({ url: Request.requestMapping.util.jobTitles, params: { name: namePart } });
  };

  getCountry = async () => {
    return await Request.get({ url: Request.requestMapping.util.countries });
  };

  //   getUserID = async () => {
  //     if (this.state.userID === -1) {
  //       let token = await this.tokenPromise;
  //       if (token) {
  //         var decoded = jwt_decode(token);

  //         let userObject;
  //         if (decoded.sub) {
  //           userObject = JSON.parse(decoded.sub);
  //           if (userObject) {
  //             return userObject.user.id;
  //           }
  //         }
  //       }
  //     }
  //     return this.state.userID;
  //   };

  //   setToken = token => {
  //     if (token) {
  //       var decoded = jwt_decode(token);

  //       let userObject;
  //       if (decoded.sub) {
  //         userObject = JSON.parse(decoded.sub);
  //       }
  //       if (userObject) {
  //         this.updateName(
  //           userObject.personalInfo
  //             ? `${userObject.personalInfo.firstName} ${userObject.personalInfo.lastName}`
  //             : userObject.user.username
  //         );
  //         this.updateUserId(userObject.user.id);
  //       }
  //       this.setState({ loggedIn: true });
  //       Request.setAuth(token);
  //     }
  //   };

  //   getUserData() {
  //     return Cookies.get('th-userinfo');
  //   }

  //   async setUserData() {
  //     Cookies.set('ack-login', 'value');
  //   }

  //   updateUserId = userId => {
  //     this.setState({ userID: userId });
  //   };

  //   updateRole = userRole => {
  //     this.setState({ role: userRole });
  //   };

  //   updateName = userName => {
  //     this.setState({ userName: userName });
  //   };

  render() {
    return <UtilityContext.Provider value={this.state}>{this.props.children}</UtilityContext.Provider>;
  }
}

export function withUtilities(Component) {
  return function UtilityAccessComponent(props) {
    const { forwardedRef, ...rest } = props;

    return (
      <UtilityContext.Consumer>
        {context => <Component {...context} ref={forwardedRef} {...rest} />}
      </UtilityContext.Consumer>
    );
  };

  // return React.forwardRef((props, ref) => {
  //   return <UserAwareComponent {...props} forwardedRef={ref} />;
  // });
}
