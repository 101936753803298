import React from "react";
import "./App.scss";
import logo from "./assets/target-hype-text.png";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Request from "./request";

import UnderConstruction from "./pages/under-construction";
import Header from "./shared/components/header/header";
import Questionnaire from "./pages/public/questionnaire/questionnaire";
import HomePage from "./pages/public/home/home";
import B2BPage from "./pages/public/b2b/index";
import Footer from "./shared/components/footer/footer";
import IndividualsPage from "./pages/public/individuals";
import AboutPage from "./pages/public/about";
import LoginPage from "./pages/public/login";
import ProfilePage from "./pages/private/profile";
import { THDialog } from "./shared/components/dialog";
import THButton from "./shared/components/button/button";
import { UserProvider } from "./shared/context/user";
import { UtilityProvider } from "./shared/context/util";
import moment from "moment";
import ResetPassword from "./pages/public/util-pages/reset-password/reset-password";
import ActivateAccount from "./pages/public/util-pages/activate-account/activate-account";
import TermsAndConditionsPage from "./pages/public/terms-and-conditions/terms-and-conditions";
import PrivacyPolicyPage from "./pages/public/privacy-policy/privacy-policy";

// import logo from './assets/UnderConstruction-small.jpg';

let jwtdec = null;

export class App extends React.Component {
  state = {
    cookieDialog: false,
  };

  componentDidMount() {
    let cookieConfirmed = localStorage.getItem("th-cookie-confirm");
    if (!cookieConfirmed) {
      this.setState({ cookieDialog: true });
      return;
    }
    let confirmation = JSON.parse(cookieConfirmed);
    console.log(
      "date difference in years",
      confirmation.date,
      moment(confirmation.date).diff(moment(), "years")
    );
    if (
      confirmation.accepted !== "true" ||
      moment(confirmation.date).diff(moment(), "years") > 3
    ) {
      this.setState({ cookieDialog: true });
      return;
    }
  }

  saveAcknowledge = () => {
    localStorage.setItem(
      "th-cookie-confirm",
      JSON.stringify({ accepted: "true", date: moment() })
    );
    this.setState({ cookieDialog: false });
  };
  render() {
    return (
      <Router>
        <UtilityProvider>
          <UserProvider>
            <div className="page-content-container">
              <Header />
              <Switch>
                <Route exact path="/" component={() => <HomePage />} />
                <Route
                  exact
                  path="/register"
                  component={() => <Questionnaire />}
                />
                <Route exact path="/businesses" component={() => <B2BPage />} />
                <Route
                  exact
                  path="/individuals"
                  component={() => <IndividualsPage />}
                />
                <Route exact path="/about" component={() => <AboutPage />} />
                <Route exact path="/login" component={() => <LoginPage />} />
                <Route
                  exact
                  path="/terms-and-conditions"
                  component={() => <TermsAndConditionsPage />}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  component={() => <PrivacyPolicyPage />}
                />

                <Route
                  exact
                  path="/activation/:jwt"
                  component={() => <ActivateAccount />}
                />
                <Route
                  exact
                  path="/reset/:jwt"
                  component={() => <ResetPassword />}
                />

                <PrivateRoute
                  exact
                  path="/profile"
                  component={() => <ProfilePage />}
                />

                <Route component={() => <UnderConstruction />} />
              </Switch>
              <Footer />
            </div>
            <THDialog
              bottomSnack={true}
              close={() => this.setState({ cookieDialog: false })}
              open={this.state.cookieDialog}
              fullWidthDialogContent={true}
            >
              <div style={{ display: "flex", padding: "16px", margin: "auto" }}>
                <div style={{ margin: "auto" }}>
                  <p
                    style={{
                      paddingLeft: "16px",
                      color: "#77787b",
                      fontWeight: "600",
                    }}
                  >
                    We use cookies to improve your experience on our website.
                  </p>

                  <p
                    style={{
                      paddingLeft: "16px",
                      color: "#77787b",
                      fontWeight: "600",
                    }}
                  >
                    To find out more, read our{" "}
                    <a
                      style={{ fontSize: "16px" }}
                      href="https://targethype.com/privacy-policy"
                      target="_top"
                    >
                      Privacy & Cookie Policy
                    </a>
                  </p>
                  <div
                    style={{
                      paddingRight: "auto",
                      paddingLeft: "auto",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <THButton
                      style={{
                        width: "90%",
                        margin: "auto",
                        height: "60px",
                        fontSize: "18px",
                      }}
                      onClick={this.saveAcknowledge}
                      text="I'M OKAY WITH THAT"
                    />
                  </div>
                </div>
              </div>
            </THDialog>
          </UserProvider>
        </UtilityProvider>
      </Router>
    );
  }
}

export const AuthHelper = {
  isAuthenticated: (props) => {
    return true;
    // if (jwtdec || Request.getAuth()) {
    //   return true;
    // } else {
    //   let thCookie = Cookies.get('th-userinfo');
    //   if (!thCookie) {
    //     return false;
    //   }

    //   jwtdec = jwt_decode(thCookie);
    //   return true;
    // }
  },

  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return AuthHelper.isAuthenticated(rest) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

export default App;
