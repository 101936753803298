import React from 'react';

import './button.scss';
export default class THButton extends React.Component {
  static inputId = 0;
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  handleClick = () => {
    
    if (this.props.toggleState) {
      this.setState({ active: !this.state.active });
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  renderInput() {
    switch (true) {
      case this.props.value !== undefined:
        return <input type={this.props.type} value={this.props.value} name={this.props.name} />;
      case this.props.defaultValue !== undefined:
        return <input type={this.props.type} defaultValue={this.props.defaultValue} name={this.props.name} />;
      default:
        return <input type={this.props.type} name={this.props.name} />;
    }
  }
  render() {
    
    const buttonClass = this.props.toggleState ? 'th-button inactive' : 'th-button';
    const buttonText = this.props.toggledText && !this.props.toggleState ? this.props.toggledText : this.props.text;
    let { toggleState, toggledText, onClick, ...rest } = { ...this.props };

    return (
      <button
        className={buttonClass}
        type={this.props.type ? this.props.type : 'button'}
        onClick={this.handleClick}
        {...rest}
      >
        {buttonText}
      </button>
    );
  }
}
