import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router';
import THInput from '../../../shared/components/input/input';
import THButton from '../../../shared/components/button/button';
import Cookies from 'js-cookie';
import { THDialog, DialogActions } from '../../../shared/components/dialog';
import './styles.scss';
import Request from '../../../request';
import moment from 'moment';
import { withUserInfo } from '../../../shared/context/user';

class LoginPage extends Component {
  state = {
    error: '',
    loggedIn: false,
    forgotUsernameDialog: false,
    forgotPasswordDialog: false,
    passwordResetEmail: '',
  };
  login = async event => {
    event.preventDefault();
    this.username.setError(' ');
    let username = event.target.username.value;
    let password = '' + event.target.password.value;
    let foreverLogin = event.target.forever_login.checked;
    let payload = {
      username: username.toLowerCase(),
      password: password,
    };

    let { data, error, headers } = await Request.post({
      url: Request.requestMapping.login,
      data: payload,
    });

    if (data) {
      Request.setAuth(data);
      this.props.setToken(data);
      Cookies.remove('th-userinfo');

      let cookieConfirmed = localStorage.getItem('th-cookie-confirm');
      if (cookieConfirmed) {
        let confirmation = JSON.parse(cookieConfirmed);
        if (confirmation.accepted === 'true' && moment(confirmation.date).diff(moment(), 'years') <= 3) {
          if (foreverLogin) {
            Cookies.set('th-userinfo', data);
          }
        }
      }

      this.setState({ loggedIn: true });
      return;
    }
    if (error) {
      if (error.message.error === 'Unauthorized') {
        let errorMessage = typeof error === 'object' ? error.message.message : JSON.parse(error.message.message);
        if (errorMessage.locked) {
          this.username.setError(() => (
            <p>
              Account locked, please contact{' '}
              <a href="mailto:recruiter@targethype.com?Subject=TargetHype Username assistance" target="_top">
                recruiter@targethype.com
              </a>
            </p>
          ));
        } else {
          this.username.setError(
            `The username or password you entered is incorrect ${
              errorMessage.logins ? ',' + 11 - errorMessage.logins + ' attempts remaining' : ''
            }`
          );
        }

        return;
      }
      this.username.setError('The username or password you entered is incorrect');
    }
  };

  sendPasswordResetEmail = async emailToReset => {
    console.log(emailToReset);
    let { data, error, headers } = await Request.post({
      url: Request.requestMapping.resetPasswordEmail,
      data: { username: emailToReset },
    });

    this.resetEmailPasswordRef.setInfo(`A reset password email has been sent to ${emailToReset}.`, true);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loggedIn && <Redirect to="/profile"></Redirect>}

        {!this.state.loggedIn && (
          <div>
            <THDialog
              close={() => this.setState({ forgotUsernameDialog: false })}
              open={this.state.forgotUsernameDialog}
              // actions={() => <THButton onClick={() => this.setState({ forgotUsernameDialog: false })} text="OK" />}
            >
              <div style={{ padding: '16px', margin: 'auto', maxWidth: '400px' }}>
                <p style={{ paddingLeft: '16px', color: '#77787b', fontWeight: '600' }}>Forgot your username? </p>

                <p style={{ paddingLeft: '16px', color: '#77787b', fontWeight: '600' }}>
                  Please contact us at{' '}
                  <a
                    style={{ fontSize: '16px' }}
                    href="mailto:recruiter@targethype.com?Subject=TargetHype Username assistance"
                    target="_top"
                  >
                    recruiter@targethype.com
                  </a>
                </p>

                <p style={{ paddingLeft: '16px', color: '#77787b', fontWeight: '600' }}>
                  We may ask you to provide some personal information to be able to match your profile.{' '}
                </p>
                <div
                  style={{
                    paddingRight: 'auto',
                    paddingLeft: 'auto',
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <a
                    style={{ display: 'flex', width: '100%', margin: 'auto', height: '60px' }}
                    href="mailto:recruiter@targethype.com"
                  >
                    <THButton
                      style={{ width: '90%', margin: 'auto', height: '60px' }}
                      onClick={() => this.setState({ forgotUsernameDialog: false })}
                      text="CONTACT US"
                    />
                  </a>
                </div>
              </div>
            </THDialog>

            <THDialog
              close={() => this.setState({ forgotPasswordDialog: false })}
              open={this.state.forgotPasswordDialog}
              // actions={() => <THButton onClick={() => this.setState({ forgotUsernameDialog: false })} text="OK" />}
            >
              <div style={{ padding: '16px', margin: 'auto', maxWidth: '400px' }}>
                <p style={{ paddingLeft: '16px', color: '#77787b', fontWeight: '600', maxWidth: '300px' }}>
                  Forgot your password?{' '}
                </p>

                <p style={{ paddingLeft: '16px', color: '#77787b', fontWeight: '600' }}>
                  Please enter your email address to receive a password reset email.
                </p>

                <div
                  style={{
                    display: 'flex',
                    width: '90%',
                    margin: 'auto',
                    height: '60px',
                    paddingTop: '10px',
                    paddingBottom: '40px',
                  }}
                >
                  <THInput
                    ref={ref => (this.resetEmailPasswordRef = ref)}
                    type="email"
                    name={'email'}
                    onChange={event => this.setState({ passwordResetEmail: event.target.value })}
                    initialLoad={this.state.loading}
                    label={() => <b style={{ fontSize: '16px', fontWeight: '600' }}>Email Address / Username</b>}
                    value={this.state.passwordResetEmail}
                  />
                </div>

                <div
                  style={{
                    paddingRight: 'auto',
                    paddingLeft: 'auto',
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <THButton
                    style={{ width: '90%', margin: 'auto', height: '60px' }}
                    onClick={() => this.sendPasswordResetEmail(this.state.passwordResetEmail)}
                    text="RESET PASSWORD"
                  />
                </div>
              </div>
            </THDialog>

            <div className="login-wrapper">
              <div className="login-container">
                <section>
                  <div className="hero">
                    <div className="hero-elements">
                      <form style={{ marginTop: '24px' }} onSubmit={this.login}>
                        <THInput
                          ref={ref => (this.username = ref)}
                          name={'username'}
                          errorMessage=" "
                          label={
                            <span style={{ fontSize: '16px', color: 'rgb(119, 120, 123)' }}>
                              <b>Email address / Username</b>
                            </span>
                          }
                        />
                        <THInput
                          ref={ref => (this.password = ref)}
                          name={'password'}
                          type="password"
                          label={
                            <span style={{ fontSize: '16px', color: 'rgb(119, 120, 123)' }}>
                              <b>Password</b>
                            </span>
                          }
                        />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <THButton type="submit" text="LOG IN" />
                        </div>
                        <div
                          style={{
                            width: '%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '16px',
                            color: '#77787b',
                          }}
                        >
                          <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <input
                              style={{ width: '20px', height: '20px', margin: '16px' }}
                              type="checkbox"
                              name={'forever_login'}
                            />
                            <span>Keep me logged in</span>
                          </label>
                        </div>
                        <div
                          style={{
                            width: '%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '16px',
                            color: '#77787b',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: '600',
                              fontSize: '20px',
                              textAlign: 'center',
                            }}
                          >
                            Don't have an account? <br />
                            <NavLink to="/register">
                              <b
                                style={{
                                  fontWeight: '600',
                                  fontSize: '20px',
                                }}
                              >
                                Register here
                              </b>
                            </NavLink>
                          </span>
                        </div>
                        {/* <a href="mailto:recruiter@targethype.com" className="register-button">
                    LOG IN
                  </a> */}
                      </form>
                    </div>
                  </div>
                </section>
                <ul>
                  <li className="nav-item not-last">
                    <a style={{ fontSize: '14px' }} onClick={() => this.setState({ forgotUsernameDialog: true })}>
                      Forgot username?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a style={{ fontSize: '14px' }} onClick={() => this.setState({ forgotPasswordDialog: true })}>
                      Forgot password?
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withUserInfo(LoginPage);
